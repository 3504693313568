.messagePanelHeaderStyle {
  height: 65px;
  padding: 10px 32px;
  box-sizing: border-box;
  width: 100%;
  flex-shrink: 0;
  border-bottom: 1px solid #49494925;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafc;
  // color: ${({ theme }) => theme.messagePanel.header.title};
}

.messagePanelHeaderIcons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.username {
  font-weight: 600;
  font-size: "18px";
  line-height: "22px";
}

.iconText {
  font-family: "OpenSans";
  color: #333333;
  font-size: 14px;
}
