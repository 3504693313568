/* Variable declairation */
$colorprimary: #103170;
$appgrey: #9e9e9e;
$appred: #ff3d3d;
$appgreen: #02c8a7;
$apporange: #f9be02;
$appyellow: #fef1c8;
$lightgrey: #f6f7f9;
$applightblue: #e7fffb;
$applightblue2: #e3edff;
$appgray: #a4a4a4;

$cardshadow: 0 2px 9px 0 rgba(210, 208, 208, 0.5);

@import "../../assets/fonts/fontface/style.css";
@import "../../assets/fonts/iconfont/style.css";

/* ============= Generalize / Common Styles ============= */
html,
body {
  min-height: 100vh;
}
body {
  background: #fff;
  font-family: "OpenSans";
  font-weight: 400;
  font-size: 15px;
  background: #f5f7f8 !important;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.MuiMenu-list {
  height: 200px;
}

#profile-menu {
  .MuiMenu-list {
    height: auto !important;
  }
}

#menu-repeatSelect {
  .MuiMenu-list {
    height: auto !important;
  }
}

.child-container {
  & > div {
    &:last-child {
      margin: 0 auto;
    }
  }
}

.ip-pl-40 {
  padding-left: 40px;
}

.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: revert !important;
  input[type="checkbox"] {
    & + svg {
      filter: blur(2px);
    }
  }
}

.comingsoon-sep {
  display: none;
}
@media screen and (max-width: 1024px) {
  .comingsoon-sep {
    display: block;
    .comingSoon {
      display: block !important;
      position: static;
      margin: 0;
      background: none;
      border: 0 !important;
      border-radius: 0;
      padding: 0;
      text-align: center;
      margin-bottom: 10px;
      text-transform: capitalize;
      font-size: 10px !important;
      margin-left: 0 !important;
      border-top: solid 1px #e7e7e7 !important;
      padding-top: 10px;
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .comingSoon {
    border: 0 !important;
    padding-top: 10px !important;
    margin-top: 0 !important;
  }
}

.outlineBtn {
  border: solid 1px #103170 !important;
  color: #103170 !important;
  font-size: 20px !important;
  line-height: 18px !important;
  letter-spacing: -0.5px !important;
  border-radius: 100px !important;
  padding: 16px 21px 15px !important;
  font-weight: bold !important;
}

@media screen and (max-width: 1366px) {
  .outlineBtn {
    padding: 14px 20px !important;
    font-size: 17px !important;
  }
}

.w-100 {
  width: 100%;
}

.time-zone {
  width: 100%;
  ul {
    background: #ffffff;
    z-index: 9999;
  }
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.mt-0 {
  margin-top: 0;
}

.mt-15 {
  margin-top: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-auto {
  margin: 0 auto;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.my-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.mb-40 {
  margin-bottom: 40px;
}
.m-0 {
  margin: 0;
}
.mt-30 {
  margin-top: 30px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-10 {
  margin-bottom: 10px;
}

.ml-55 {
  margin-left: 55px;
}

.pb-16 {
  padding-bottom: 16px;
}
.py-15 {
  padding-bottom: 15px;
  padding-top: 15px;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-25 {
  padding-left: 25px;
}
.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.fw-500 {
  font-weight: 500;
}

.d-flex {
  display: flex;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}

.jc-space-between-v-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-none {
  display: none !important;
}

.bulbImage {
  width: 34px;
}

.greenPlusBtn {
  color: $appgreen;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  svg {
    width: 29px;
    height: 29px;
    color: $appgreen;
    margin-right: 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: $colorprimary;
  font-family: "fGilroy";
  line-height: 1.2;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.8em;
}
h3 {
  font-size: 1.6em;
}
h4 {
  font-size: 1.4em;
}
h5 {
  font-size: 1.2em;
}
h6 {
  font-size: 1em;
}

a {
  cursor: pointer;
}

.handCursor {
  cursor: pointer;
}

.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  border-radius: 50%;
  width: 46px;
  height: 46px;
  &.sm {
    width: 28px;
    height: 28px;
    border-width: 3px;
    border-top-color: #eee;
    border-right-color: #eee;
    border-bottom-color: #eee;
    -webkit-animation: load8 0.8s infinite linear;
    animation: load8 0.8s infinite linear;
  }
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 6px solid rgba(255, 255, 255, 0.1);
  border-right: 6px solid rgba(255, 255, 255, 0.1);
  border-bottom: 6px solid rgba(255, 255, 255, 0.1);
  border-left: 6px solid #003471;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.chat-container {
  background-color: red;
  height: 80vh;
}

.grid-body {
  flex: 1 0 auto !important;
  padding: 35px 0 20px;
  // background-color: red;
  // .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  //   padding: 1px;
  // margin: 4px;
  // }
}

.btn-rounded {
  border-radius: 100px !important;
}

/* btn disabled */
.MuiButton-contained.Mui-disabled {
  background-color: #9e9e9e !important;
  color: #eee !important;
}

/* ----- Title left aligned stroke */
.title-left-stroke {
  color: #a4a4a4;
  font-weight: 400;
  font-size: 0.85em;
  font-family: "OpenSans";
  margin: 0;
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-top: 1.5px solid rgba(16, 49, 112, 0.1);
    content: "";
  }
  span {
    background: #fff;
    padding: 0 20px 0 0;
    position: relative;
  }
}

/* ----- Title with central stroke*/
.title-center-inline {
  position: relative;
  font-weight: 500;
  text-align: center;
  &:before {
    width: 100%;
    border-top: 1px solid rgba(16, 49, 112, 0.1);
    box-shadow: 0 0.5px 0 0 rgba(16, 49, 112, 0.1);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    content: "";
    z-index: -1;
  }
  span {
    background: #f5f7f8;
    display: inline-block;
    padding: 0 30px;
  }
}

/* ----- Dual Title center inline */
.dual-title-center-inline {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 25px 0;
  position: relative;
  &:before {
    content: "";
    width: 99%;
    border-top: 1px solid rgba(16, 49, 112, 0.1);
    box-shadow: 0 -0.5px 0 0 rgba(16, 49, 112, 0.1);
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 0 11px 0.5%;
    z-index: -1;
  }
  p {
    margin: 0;
    background: #f5f7f8;
    padding-right: 30px;
    span {
      color: #a4a4a4;
      font-size: 0.85em;
    }
    h5 {
      margin: 0;
      font-weight: 500;
      font-size: 1.3em;
    }
    & + p {
      padding-right: 0;
      padding-left: 30px;
      span {
        text-align: right;
        display: block;
      }
      h5 {
        color: #e54644;
        &.role-therapist {
          color: $appgreen;
        }
        &.role-co-guardian {
          color: $apporange;
        }
      }
    }
  }
}

/* ----- child card */
.child-card {
  box-shadow: $cardshadow !important;
  border-radius: 12px !important;
  padding: 25px;
  // height: calc(100% - 50px);
  height: 190px;
  .child-profile-row {
    display: flex;
    align-items: center;
    height: 115px;
    .imgLoader {
      figure {
        position: relative;
        .loader {
          position: absolute;
          // left: 1px;
          // top: -10px;
          // width: 40px;
          // height: 40px;
          left: 0;
          top: 0;
          width: 47px;
          height: 47px;
        }
      }
    }
    figure {
      margin-right: 18px;
      max-width: 52px;
      max-height: 52px;
      // height: auto;
      border-radius: 100px;
      border: 3px solid #fff;
      box-shadow: 0 0 0 4px $apporange;
      // flex: 0 0 auto;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100px;
        aspect-ratio: 1/1;
      }
    }
    .child-name-status {
      flex: 1 1 auto;
      padding: 0 20px 0 0;
      h5 {
        margin: 0 0 4px 0;
        color: #333;
        font-family: "OpenSans";
        font-weight: 500;
        line-height: 1.4;
      }
      span {
        color: $appgreen;
      }
      .code-label-align {
        display: flex;
        align-items: center;
        margin: 10px 0 0 0;
        .code-label {
          margin-right: 8px;
        }
        .MuiSvgIcon-root {
          font-size: 20px !important;
        }
      }
    }

    .child-card-more {
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background: rgba(2, 200, 167, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $appgreen;
      font-size: 1.2em;
      transition: 0.15s;
      flex: 0 0 auto;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  i[class*="ticon-sync-"] {
    display: flex;
    align-items: center;
    font-size: 1.8em;
    margin: 8px 0 0 0;
    position: relative;
    top: 2px;
    &.ticon-sync-off {
      color: $appred;
      span {
        color: #f07770;
      }
    }
    &.ticon-sync-on {
      color: $appgreen;
    }
  }
  .iwatch-status {
    font-size: 14px !important;
    margin: 0 0 4px 4px;
    font-family: "OpenSans";
    font-weight: 500;
  }
  .code-label {
    // background-color:rgba(2, 200, 167, 0.1);
    background-color: #eff5ff;
    padding: 4px 4px 4px 5px;
    border-radius: 2px;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 13px;
    color: black !important;
  }
  .progress-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 5px;
    margin: 0 5px;
    // display: none;
    span {
      font-family: "OpenSans";
      color: $colorprimary;
      font-weight: 500;
      font-size: 0.95em;
      &:last-child {
        font-weight: 400;
        color: #a4a4a4;
      }
    }
  }
  .progress-bar {
    position: relative;
    // display: none;
    .progress-separator {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 6px;
      width: 100%;
      z-index: 9;
      em {
        width: 1px;
        height: 6px;
        border-left: 1px solid #fff;
      }
    }
  }
  .MuiLinearProgress-colorPrimary {
    background: #ececec;
    height: 6px;
    margin: 0 5px;
    .MuiLinearProgress-barColorPrimary {
      background: #4e6695;
    }
  }
}

/* ----- Others ---- */
.hint-text {
  color: #999;
  font-size: 0.8em;
}

.MuiDialog-paper {
  .popup-close {
    width: 16px;
    fill: #999;
    float: right;
    position: absolute;
    right: 0;
    margin: 7px 15px 0 0;
    cursor: pointer;
    &:hover {
      fill: $appred;
    }
  }
  h2 {
    font-family: "fGilroy" !important;
    padding-top: 5px;
    line-height: 1.15;
    letter-spacing: -0.2px;
    width: calc(100% - 20px);
  }
  .MuiDialogContent-root {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiDialogActions-root {
    padding: 18px;
    .MuiButton-root {
      font-family: "OpenSans";
      border-radius: 200px;
      font-size: 0.9em;
      font-weight: 500 !important;
      padding: 7px ​16px;
    }
  }
  .no-button-text {
    text-transform: none;
  }
}

.title-has-icon {
  display: flex;
  align-items: center;
  .back-arrow {
    transform: rotate(-180deg);
    font-size: 0.85em;
    display: inline-block;
    margin: 4px 7px 0 0;
    cursor: pointer;
  }
}

button {
  box-shadow: none !important;
  [class*="ticon-"] {
    margin: 0 8px 0 0;
    font-size: 0.9em;
  }
}

.MuiTableContainer-root {
  background: #fff;
  border: solid 1px #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.07);
  &.shadow-none {
    box-shadow: none;
    border-width: 2px;
  }
}
.MuiTable-root {
  thead,
  tbody {
    .MuiTableCell-root {
      padding: 14px 20px;
      font-family: "OpenSans";
      font-size: 1em;
      letter-spacing: -0.2px;
    }
  }
  thead {
    background-color: #f5f7f8;
    tr {
      .MuiTableCell-head {
        color: $colorprimary;
      }
    }
  }
  tbody {
    tr {
      td {
        &.MuiTableCell-root {
          border-bottom: solid 1px #e0e0e0;
          font-size: 0.9em;
          color: #333;
          .MuiSwitch-root {
            padding: 0;
            height: 20px;
            border-radius: 100px;
          }
          .ticon-trash {
            color: $appred;
            font-size: 1.35em;
          }
        }
        .table-profile {
          display: flex;
          align-items: center;
          figure {
            width: 36px;
            height: 36px;
            border-radius: 100px;
            margin: 0 12px 0 0;
            position: relative;
            .loader {
              width: 25px;
              height: 25px;
              position: absolute;
              margin: 0;
            }
            img {
              width: 36px;
              height: 36px;
              border-radius: 100px;
              object-fit: cover;
            }
          }
        }
        .MuiCheckbox-root {
          padding: 0;
          input {
            & + .MuiSvgIcon-root {
              fill: #bbb;
              font-size: 1.1em;
            }
            &:checked {
              & + .MuiSvgIcon-root {
                fill: $appgreen;
              }
            }
          }
        }
        &:nth-child(2) {
          img {
            object-fit: contain !important;
          }
        }
      }
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
  &.has-profile-photo {
    tbody {
      .MuiTableCell-root {
        padding: 8px 20px;
      }
    }
  }
}

.has-switch {
  display: flex;
  border-radius: 100px;
  background: #f9f9f9;
  align-items: center;
  // max-width: 84px;
  max-width: 110px;
  margin: 0 auto;
  padding: 8px 12px;
  justify-content: flex-end;
  strong {
    text-transform: capitalize;
    color: #acacac;
    font-size: 0.9em;
    font-weight: 400;
    margin-right: 7px;
  }
  .MuiSwitch-root {
    width: 36px;
    .MuiSwitch-switchBase {
      padding: 5px !important;
      .MuiSwitch-thumb {
        width: 10px;
        height: 10px;
        box-shadow: none;
      }
      &.MuiSwitch-colorPrimary {
        &.Mui-checked {
          transform: translateX(16px);
          & + .MuiSwitch-track {
            background: #02c8a7;
            opacity: 1;
          }
          .MuiSwitch-thumb {
            background: #fff;
          }
        }
      }
    }
  }
  &.switch-active {
    background-color: rgba(2, 200, 167, 0.06);
    strong {
      color: $appgreen;
    }
  }
}

.generic-card {
  box-shadow: 0 2px 9px 0 rgba(210, 208, 208, 0.5) !important;
  border-radius: 12px !important;
  padding: 30px;
  background: #fff;
  border: 0;
  h1 {
    margin-top: 0;
  }
  .imageLibraryScroll {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 500px;
    margin: 0 -8px;
    margin-right: -11px;
    ul {
      li {
        img {
          object-fit: contain;
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .imageLibraryScroll {
      margin-right: -19px;
    }
  }
}

.generic-card-full-height {
  .generic-card {
    height: 99%;
    position: relative;
    box-sizing: border-box;
  }
}

.custom-chip {
  border-radius: 30px;
  font-size: 0.8em;
  font-family: "OpenSans";
  font-weight: 600;
  background: #f9be02;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  margin: 4px 3px;
  svg {
    // border-left: 1px solid #ccc;
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 1em;
    padding: 6px 3px;
    margin-right: -7px;
    margin-left: 6px;
    cursor: pointer;
    &:hover {
      color: $appred;
    }
  }
  .chip-medical {
    background: #e0e0e0;
  }
}

.check-block {
  width: 100%;
  max-width: 170px;
  float: left;
  margin: 0 20px 10px 0;
  .MuiFormControlLabel-root {
    margin: 0;
    width: 100%;
    height: 100%;
    .MuiCheckbox-root {
      display: none;
      &.Mui-checked {
        & + span {
          p {
            background: $appgreen;
            opacity: 1;
            box-shadow: none;
            strong {
              color: #fff;
            }
            &:after {
              color: $appgreen;
            }
          }
        }
      }
    }
    & > span {
      &:last-child {
        width: 100%;
        height: 100%;
        p {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.2) inset;
          border-radius: 10px;
          margin: 0;
          padding: 20px;
          box-sizing: border-box;
          opacity: 0.45;
          position: relative;
          min-height: 121px;
          figure {
            width: 50px;
            height: 50px;
            border-radius: 200px;
            margin: 0;
            img {
              width: 100%;
              height: 100%;
              border-radius: 200px;
              object-fit: cover;
            }
          }
          strong {
            display: block;
            margin: 0;
            font-family: "OpenSans";
            font-weight: 500;
            font-size: 1em;
            color: #888;
            padding-top: 7px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            text-align: center;
          }
          &:after {
            width: 20px;
            height: 20px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "fonticon";
            content: "\e90d";
            font-size: 0.72em;
            color: #bbb;
            border-radius: 50px;
            top: 0;
            right: 0;
            z-index: 1;
            background: #fff;
            margin: 10px 10px 0 0;
          }
        }
      }
    }
  }
}

.no-child-found {
  color: #bbb;
  padding: 6px 15px;
  margin: 10px 0 0 12px;
  font-size: 1.05em;
  border: 1px solid #eee;
  border-radius: 8px;
}

.radio-block {
  // width: 100%;
  // max-width: 170px;
  // float: left;
  // margin: 0 20px 10px 0;
  .MuiFormControl-root,
  .MuiFormGroup-root {
    width: 100%;
  }
  .MuiFormControlLabel-root {
    // margin: 0;
    // width: 100%;
    // height: 100%;
    width: 100%;
    height: 100%;
    max-width: 170px;
    float: left;
    margin: 0 20px 10px 0;
    .MuiRadio-root {
      display: none;
      &.Mui-checked {
        & + span {
          p {
            box-shadow: 0 0 0 1.5px #02c8a7 inset;
            strong {
              color: #9e9e9e;
            }
            &:after {
              border-color: $appgreen;
              box-shadow: none;
              border-width: 6px;
              margin: 10px 10px 0 0;
            }
            i {
              color: $appgreen;
            }
          }
        }
      }
    }
    & > span {
      &:last-child {
        width: 100%;
        height: 100%;
        p {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.15) inset;
          border-radius: 10px;
          margin: 0;
          padding: 20px;
          box-sizing: border-box;
          position: relative;
          min-height: 121px;
          i {
            font-size: 2.5em;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #a4a4a4;
          }
          strong {
            display: block;
            margin: 0;
            font-family: "OpenSans";
            font-weight: 500;
            font-size: 1em;
            color: #888;
            padding-top: 7px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // width: 100%;
            text-align: center;
          }
          &:after {
            width: 8px;
            height: 8px;
            content: "";
            position: absolute;
            border-radius: 50px;
            top: 0;
            right: 0;
            z-index: 1;
            background: #fff;
            margin: 11px 11px 0 0;
            border: 4px solid #fff;
            box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
  &:nth-of-type(2) {
    i.ticon-teacher {
      &.ticon-teacher {
        &:before {
          content: "\e90f" !important;
        }
      }
    }
  }
}

/* ----- Buttons Footer ---- */
.buttons-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  .btn-delete {
    float: left;
    flex: auto;
    @media screen and (max-width: 768px) {
      margin-bottom: 15px;
    }
    a {
      font-weight: 500;
      color: $appred;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .btns-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
      button {
        margin: 0 5px !important;
      }
    }
  }
  button {
    border-radius: 100px;
    padding: 8px 25px;
    font-weight: 500 !important;
    color: $appgrey;
    text-transform: none;
    margin-right: 10px;
    background: transparent !important;
    font-size: 1.05em;
    font-family: "OpenSans" !important;
    min-width: 130px;
    box-shadow: 0 0 0 1.5px $appgrey inset !important;
    letter-spacing: -0.1px;
    border: 0;
    &:hover {
      opacity: 0.85;
    }
    &.MuiButton-containedPrimary {
      font-weight: 600;
      background: $colorprimary !important;
      margin-right: 0;
      box-shadow: none !important;
      color: #fff;
    }
  }
  @media screen and (max-width: 768px) {
    button {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}

/* empty-list-card */
.empty-list-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 24px;
  img {
    max-width: 240px;
  }
  span {
    font-size: 0.95em;
    color: #bbb;
    font-weight: 500;
    display: block;
    padding: 20px;
  }
}

/* ============== Material Styles Overwrites as per Thrive theme ============ */
body {
  .MuiFormLabel-root {
    // font-weight: 600;
    color: #333;
    font-size: 0.95em;
    font-family: "OpenSans";
    font-weight: 500;
    margin-bottom: 10px;
    letter-spacing: -0.2px;
  }
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  display: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
.MuiSelect-select.MuiSelect-select,
.PhoneInput,
.date-picker input,
#afterLabel input {
  border: 1px solid #e0e0e0;
  border-radius: 9px;
  font-size: 0.95em;
  color: $colorprimary;
  padding: 9px 12px;
  &:disabled {
    opacity: 0.9;
    background-color: #eee;
    color: #999;
    cursor: not-allowed;
  }
}

.MuiButton-containedPrimary {
  background: $colorprimary !important;
  text-transform: none !important;
  font-family: "fGilroy" !important;
  font-weight: 500 !important;
}

// .MuiButton-containedSecondary{
//   background: transparent;
//   box-shadow: 0 0 0 1.5px #979797;
// }

/* Checkbox */
.MuiIconButton-colorSecondary:hover {
  background: transparent !important;
}
.MuiTouchRipple-root {
  display: none;
}
.MuiFormControlLabel-root {
  align-items: flex-start !important;
} /* Checkbox wrapper */
.MuiFormControlLabel-label {
  line-height: 1.25;
} /* Checkbox label */
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #f9be02 !important;
} /* Checkbox box */

.MuiAlert-root {
  .MuiAlert-icon {
    padding: 5px 0;
  }
  .MuiAlert-message {
    padding: 5px 0;
  }
}

.PhoneInput {
  padding-top: 9px;
  padding-bottom: 9px;
  .PhoneInputInput {
    padding: 0;
    border: 0;
    font-size: 1.05em;
    border-radius: 0;
    &:focus {
      outline: none;
    }
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #a7b4cd;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #a7b4cd;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #a7b4cd;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: #a7b4cd;
    }
  }
  .PhoneInputCountry {
    border-right: 1px solid #ddd;
    padding-right: 10px;
    margin-top: -11px;
    margin-bottom: -11px;
    .PhoneInputCountrySelectArrow {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #aaa;
      border-bottom: 0;
      transform: none;
      opacity: 1;
    }
  }
}

.MuiFormLabel-asterisk {
  color: $appred;
}
/* End Overwrites */

/* ============= Login / Signup =========== */
.is-login {
  min-height: 100vh;
  & > .MuiGrid-container {
    min-height: 100vh;
  }
  .screen-half-splash {
    // background-color: #f5f7f8;
    background: url(../../assets/images/splashes.jpg) no-repeat 0 0;
    background-size: contain;
    background-color: #fff !important;
    background-position: center center;
  }
  @media screen and (max-width: 599px) {
    .screen-half-splash {
      display: none;
    }
  }
  .login-form {
    padding: 24px 32px;
    background: #fff;
    display: flex;
    justify-content: start;
    align-items: center;
    .login-form-content {
      width: 100%;
      max-width: 380px;
    }
    .signup-form-content {
      width: 100%;
      max-width: 450px;
      padding: 20px 0;
      .login-logo {
        margin-top: 0;
        margin-bottom: 50px;
      }
    }
  }
  form {
    max-width: 380px;
    h1 {
      text-transform: capitalize;
      margin-bottom: 50px;
    }
    .forgotPasswordText {
      margin-top: -10px;
      margin-bottom: 20px;
    }
    // input[type="email"],
    // input[type="password"] {
    //   height: 59px;
    //   border-radius: 5px;
    //   box-sizing: border-box;
    // }
    .form-field {
      width: 100%;
      max-width: 380px;
      padding: 5px 0 15px;
    }
    .MuiInput-root {
      width: 100%;
    }
    input[type="text"],
    input[type="email"],
    input[type="password"] {
      width: 100%;
    }
    .login-button {
      padding-bottom: 0 !important;
      .MuiButton-containedPrimary {
        width: 100%;
        border-radius: 100px;
        padding: 10px 15px;
        font-size: 1em;
        margin-bottom: 10px;
      }
    }
    .link-forgot-pass,
    .link-signup {
      text-align: center;
      font-weight: 400;
      color: #787474;
      text-align: center;
      margin: 12px 0 0;
      font-size: 0.9em;
      a {
        color: $colorprimary;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .keep-login {
      margin-top: 5px;
      .MuiCheckbox-root {
        padding: 0 6px 0 9px;
        svg {
          width: 0.9em;
          height: auto;
          font-size: 0.85em;
          color: #02c8a7;
        }
      }
      .MuiFormControlLabel-label {
        font-size: 0.85em;
        color: $colorprimary;
        font-weight: 500;
        font-family: "OpenSans";
      }
      .subscribe-offer {
        .MuiFormControlLabel-label {
          font-weight: 600;
          color: #a4a4a4;
        }
      }
      .terms-privacy-container {
        margin-left: -2px;
        font-size: 14px;
        color: #787474;
        a {
          color: $colorprimary;
          font-size: 15px;
          text-decoration: underline;
        }
        span {
          margin: 0;
          padding-left: 0 !important;
        }
      }
    }
    .MuiAlert-root {
      margin-bottom: 10px;
    }
  }
  .login-logo {
    max-width: 96px;
    height: auto;
    margin: 25px 0 0;
  }
  .MuiFormLabel-root {
    font-size: 0.9em;
    color: $colorprimary;
    padding: 0 0 10px;
    margin-bottom: 0;
  }
  &.page-signup {
    .screen-half-splash {
      height: auto;
    }
    form {
      max-width: 450px;
      .form-field {
        padding-bottom: 0;
        max-width: 450px;
        .PhoneInput {
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus {
            box-shadow: 0 0 0px 1000px #fff inset;
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            background-color: transparent;
          }
        }
        &.keep-login {
          padding: 15px 0;
        }
      }
      .hint {
        margin: 5px 0 0;
        span {
          font-weight: 500;
          color: #a09e9e;
          text-align: center;
          margin: 0px 6px 0px;
          font-size: 0.8em;
          display: flex;
          align-items: center;
          line-height: 1.2;
          em {
            color: $appred;
            font-style: normal;
            font-size: 1.4em;
            margin: 5px 3px 0 0;
          }
        }
      }
      .terms-agree {
        font-size: 0.9em;
        color: #787474;
        margin: 18px 0 12px;
        font-weight: 500;
      }
    }
  }
}

.is-login form input[type="text"] + .MuiInputAdornment-positionEnd {
  background: #ffffff;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin: 0 10px 0 0;
}

.is-login form input[type="password"] + .MuiInputAdornment-positionEnd {
  background: #ffffff;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  width: 32px;
  margin: 0 10px 0 0;
}

.success-msg {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  .success-msg-content {
    flex: 1 0 auto;
    padding: 24px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    .center-plot {
      max-width: 480px;
      width: 100%;
      .login-logo {
        margin: 35px 0 20px;
      }
      h1 {
        max-width: 480px;
        margin: 30px 0 10px;
      }
      p {
        max-width: 480px;
      }
      .btn-rounded {
        min-width: 125px;
      }
      .activation-timer {
        max-width: 125px;
        text-align: center;
        color: #a4a4a4;
        padding-top: 5px;
        span {
          font-size: 0.9em;
          color: #a4a4a4;
        }
      }
    }
  }
  .success-msg-footer {
    padding: 20px 0;
  }
}

/* =========== App Header =========== */
.app-header {
  background: #fff;
  box-shadow: $cardshadow;
  .header-logo {
    max-width: 50px;
  }
  .header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .app-notification {
      cursor: pointer;
      text-decoration: none;
      i {
        color: #222;
        font-size: 2em;
        position: relative;
        em {
          font-style: normal;
          font-size: 12px;
          color: #fff;
          background: #e54644;
          border-radius: 100px;
          min-width: 14px;
          height: 14px;
          position: absolute;
          top: 0;
          left: 10px;
          font-family: "fGilroy";
          display: flex;
          align-items: center;
          justify-content: center;
          margin: -4px 0 0 12px;
          padding: 0 3px;
          box-sizing: border-box;
        }
      }
    }
  }
  .user-profile-header {
    display: flex;
    align-items: center;
    margin-left: 1.8em;
    span {
      position: absolute;
      .loader {
        width: 40px;
        height: 40px;
      }
    }
    .MuiAvatar-circle {
      width: 48px;
      height: 48px;
      margin-right: 12px;
    }
    h5 {
      margin: 0;
      font-size: 0.95em;
      color: #333;
      line-height: 1.4;
      white-space: nowrap;
      max-width: 112px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "OpenSans";
    }
  }
  .profile-menu {
    display: flex;
    align-items: center;
    color: #9cb2cd;
    font-weight: 600;
    font-size: 0.85em;
    letter-spacing: 0.5px;
    line-height: 1.4;
    margin-top: 1px;
    cursor: pointer;
    font-family: "OpenSans";
    i {
      font-size: 0.85em;
      color: #f7ba02;
      margin: 2px 0 0 4px;
    }
  }
}

/* =========== App Footer ========= */
.app-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    margin: 0 20px;
    padding: 2px 5px;
    color: #a4a4a4;
    font-size: 1em;
    font-weight: 400;
    &:hover {
      text-decoration: none;
      color: $colorprimary;
    }
  }
  span {
    color: #a4a4a4;
    font-size: 1em;
    font-weight: 400;
  }
}

/* ============ Sidebar Menu =========== */
.sidebar-menu {
  padding: 15px 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .sidebar-profile {
    border-radius: 8px;
    border: solid 1px #e2e2e2;
    background-color: rgba(16, 49, 112, 0.02);
    padding: 4px 4px;
    .ls-elements-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .secondSidebarMenu {
      border-top: solid 1px #e7e7e9;
      padding-top: 10px;
      margin-top: 15px;
    }
    figure {
      width: 48px;
      height: 48px;
      border-radius: 100px;
      margin: 0 8px;
      overflow: hidden;
      flex: 0 0 auto;
      position: relative;
      @media screen and (max-width: 1440px) and (min-width: 1440px) {
        width: 38px;
        height: 38px;
      }
      .loader {
        position: absolute;
        width: 34px;
        height: 34px;
        left: 0;
        top: 0;
        margin: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .sidebar-profile-title {
      display: flex;
      justify-content: center;
      align-items: left;
      flex-direction: column;
      // flex: 1 0 auto;
      font-size: 0.85em;
      padding: 0 15px 0 0;
      // width: calc(100% - 85px);
      // width: calc(86% - 88px);
      h5 {
        margin: 0;
        color: #111111;
        line-height: 1.5;
        font-family: "OpenSans";
        font-weight: 500;
      }
      * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      a {
        line-height: 1.5;
        color: $appgray;
      }
      @media screen and (max-width: 1366px) {
        a {
          font-size: 11px;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .sidebar-profile-title {
        width: 100px;
        padding: 0;
        align-items: center;
      }
    }
    @media screen and (max-width: 768px) {
      .sidebar-profile-title {
        width: 62px;
        padding: 0;
      }
    }
    i {
      &.ticon-sync-on {
        font-size: 40px;
        flex: 1 0 auto;
        background: url(../../assets/images/watch-connected.svg) no-repeat 0 0;
        &::before {
          opacity: 0;
        }
      }
      &.ticon-sync-off {
        font-size: 40px;
        flex: 1 0 auto;
        background: url(../../assets/images/watch-disconnected.svg) no-repeat 0
          0;
        &::before {
          opacity: 0;
        }
      }
      @media screen and (max-width: 1366px) {
        &.ticon-sync-on {
          font-size: 30px;
        }
        &.ticon-sync-off {
          font-size: 30px;
        }
      }
    }
    span {
      color: #9e9e9e;
      display: flex;
      flex-direction: column;
      font-size: 0.75em;
      cursor: pointer;
      margin-bottom: 10px;
      transition: 0.15s;
      padding: 1px 3px;
      &:hover {
        color: $appgreen;
        transform: scale(1.2);
        padding-left: 10px;
      }
      // i {
      //   transform: rotate(-180deg);
      // }
    }
    .secondSidebarMenu {
      .sidebar-profile {
        background: none;
        border: 0;
        // border-top: solid 1px #979797;
        border-radius: 0;
        margin-top: 0;
        padding: 0;
        height: 266px;
        overflow-y: scroll;
        overflow-x: hidden;
        span {
          margin-top: 0;
          font-size: 15px;
          margin-bottom: 0;
          color: $colorprimary;
          transform: scale(1);
          margin-bottom: 15px;
        }
        > div {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e7e7e9;
          padding-bottom: 5px;
          margin-bottom: 5px;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border: 0;
          }
        }
        h5 {
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
      }
      .sidebar-profile-one {
        background: none;
        border: 0;
        // border-top: solid 1px #979797;
        border-radius: 0;
        margin-top: 0;
        padding: 0;
        height: 100px;
        overflow-y: scroll;
        overflow-x: hidden;
        span {
          margin-top: 0;
          font-size: 15px;
          margin-bottom: 0;
          color: $colorprimary;
          transform: scale(1);
          margin-bottom: 15px;
        }
        > div {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e7e7e9;
          padding-bottom: 5px;
          margin-bottom: 5px;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border: 0;
          }
        }
        h5 {
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
      }
      .sidebar-profile-two {
        background: none;
        border: 0;
        // border-top: solid 1px #979797;
        border-radius: 0;
        margin-top: 0;
        padding: 0;
        height: 175px;
        overflow-y: scroll;
        overflow-x: hidden;
        span {
          margin-top: 0;
          font-size: 15px;
          margin-bottom: 0;
          color: $colorprimary;
          transform: scale(1);
          margin-bottom: 15px;
        }
        > div {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e7e7e9;
          padding-bottom: 5px;
          margin-bottom: 5px;
          &:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
            border: 0;
          }
        }
        h5 {
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
      }
      @media screen and (max-width: 1366px) {
        span {
          font-size: 11px !important;
        }
        h5 {
          font-size: 11px !important;
        }
      }
      @media screen and (max-width: 768px) {
        span {
          position: static;
        }
      }
    }
  }
  .menu-list {
    border-top: 1px solid #f3f3f3;
    margin: 15px -15px 0;
    padding: 15px;
    padding-bottom: 80px;
    .MuiListItem-button {
      display: flex;
      align-items: center;
      border-radius: 6px;
      margin: 3px 0;
      justify-content: center;
      &:hover {
        background: #fafafa;
      }
      &.selected {
        background: rgba(2, 200, 167, 0.08);
        .MuiListItemText-root {
          span {
            color: $appgreen;
            font-weight: 600;
          }
        }
        i {
          color: $appgreen;
        }
      }
      .MuiListItemText-root {
        span {
          font-weight: 400;
          font-family: "OpenSans";
          font-size: 0.9em;
          color: #a4a4a4;
          letter-spacing: -0.2px;
        }
      }
      i {
        color: rgba(0, 0, 0, 0.22);
        max-width: 20px;
        text-align: center;
        margin: 0 10px 0 0;
        padding: 4px;
        font-size: 1.3em;
        @media screen and (max-width: 1440px) and (min-width: 1440px) {
          margin-right: 8px;
        }
        &.ticon-emoti {
          font-size: 1.45em;
          margin-top: -4px;
        }
        &.ticon-graph {
          font-size: 1.4em;
          margin-top: -4px;
        }
        &.ticon-lotus {
          font-size: 0.9rem;
        }
      }
    }
    @media screen and (max-width: 1366px) {
      .MuiListItem-button {
        padding-left: 0 !important;
        padding-right: 0;
        i {
          font-size: 1.2em;
        }
        .MuiListItemText-root {
          span {
            font-size: 0.7em;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .sidebar-profile {
      padding-left: 6px;
      padding-right: 6px;
      figure {
        width: 35px;
        height: 35px;
        .loader {
          width: 24px;
          height: 24px;
        }
      }
      .sidebar-profile-title {
        h5 {
          font-size: 13px;
        }
        // * {
        //   overflow: visible;
        // }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .sidebar-profile {
      // flex-flow: column;
      align-items: flex-start;
      figure {
        margin-bottom: 5px;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .comingSoon {
      margin-left: 70px;
      white-space: nowrap;
      border: solid 1px #c1bebe;
    }
  }
  @media screen and (max-width: 1366px) {
    .comingSoon {
      font-size: 8px;
      margin-left: 57px;
    }
  }
  @media screen and (max-width: 1137px) {
    .comingSoon {
      margin-left: 50px;
      white-space: nowrap;
      border: solid 1px #c1bebe;
    }
  }
  @media screen and (max-width: 1024px) {
    .comingSoon {
      display: none;
    }
  }
}

/* =========== Dashboard ============ */
.db-child-list {
  padding-top: 20px;
  .main-title {
    max-width: 700px;
    text-align: center;
    margin: -10px auto 0;
  }
  .title-center-inline {
    margin-top: 40px;
    font-size: 1.2em;
    margin-bottom: 30px;
  }
}

.no-child-msg {
  img {
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 275px;
    margin-bottom: 35px;
  }
  span {
    color: #a9a9b0;
    text-align: center;
    display: block;
    padding-bottom: 5px;
  }
}

.add-child-block {
  background: #eef6f6;
  border: 1px solid $appgreen;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px 30px;
  margin: 35px 0;
  h5 {
    margin: 10px 25px;
    flex: 0 0 auto;
    font-size: 1.2em;
    font-weight: 500;
  }
  button {
    &.MuiButton-containedPrimary {
      margin: 10px 25px;
      flex: 0 0 auto;
      background: $appgreen !important;
      color: #fff;
      border-radius: 100px;
      padding: 8px 46px;
      &:hover {
        opacity: 0.85;
      }
      svg {
        margin-right: 4px;
      }
    }
  }
}

/* ================= Add Child Flow ================ */
.add-child-flow {
  box-shadow: $cardshadow !important;
  border-radius: 12px !important;
  padding: 30px;
  .MuiStepper-root {
    padding-top: 10px;
    .MuiStepLabel-iconContainer {
      div {
        width: 25px;
        height: 25px;
        border-width: 1px;
        border-style: solid;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .MuiStepLabel-labelContainer {
      color: #a4a4a4;
      margin-top: -3px;
      .MuiTypography-body2 {
        font-size: 0.9em;
      }
    }
    .MuiStepConnector-line {
      border-color: #dfdfdf;
    }
    .MuiStepConnector-active {
      .MuiStepConnector-line {
        border-color: rgba(2, 200, 167, 0.4);
      }
    }
  }
  form {
    label {
      color: $colorprimary;
      font-weight: 500;
      margin: 0 0 10px;
      font-size: 0.95em;
    }
    .MuiInput-root {
      width: 100%;
    }
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    .MuiSelect-select.MuiSelect-select,
    .PhoneInput {
      width: 100%;
      color: #999;
    }
    .check-gender {
      margin: 0;
      display: flex;
      align-items: center;
      div {
        min-width: 75px;
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: #e0e0e0;
        padding: 10px 15px;
        text-align: center;
        cursor: pointer;
        color: $colorprimary;
        &:hover {
          border-color: #f9be02;
        }
        &:first-child {
          margin-right: 15px;
        }
        &:nth-child(2) {
          margin-right: 15px;
        }
      }
    }
    .profile-image-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: #f5f7f8;
      border-radius: 7px;
      padding: 20px;
      box-sizing: border-box;
      figure {
        margin: 0;
        width: 100px;
        height: 100px;
        border-radius: 200px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 200px;
          object-fit: cover;
        }
      }
      .child-photo-limit {
        color: #a09e9e;
        font-size: 0.77em;
        padding: 10px 0 20px;
      }
      .upload-photo {
        position: relative;
        width: 100%;
        max-width: 150px;
        & > div {
          position: absolute;
          left: 0;
          top: 0;
          height: 40px;
          padding: 0;
          z-index: 1;
          cursor: pointer;
          opacity: 0;
          input {
            padding: 0;
            height: 100%;
          }
        }
      }
      button {
        border: 1px solid #e0e0e0;
        background: transparent;
        color: $colorprimary;
        width: 100%;
        font-size: 0.95em;
        letter-spacing: -0.3px;
        text-transform: capitalize;
        font-weight: 400;
        max-width: 150px;
        padding: 8px ​16px;
        &:hover {
          border-color: $apporange;
        }
        &.MuiButton-containedPrimary {
          background: $appyellow !important;
          border: 1px solid $apporange !important;
          margin-bottom: 12px;
        }
      }
    }
    .PhoneInput {
      box-sizing: border-box;
      .PhoneInputInput {
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #d6d6d6;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #d6d6d6;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #d6d6d6;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #d6d6d6;
        }
      }
    }
    .title-left-stroke {
      margin: 10px 0 24px;
    }
    .MuiInputBase-multiline {
      padding: 0;
      .MuiInput-inputMultiline {
        border: 1px solid #e0e0e0;
        border-radius: 9px;
        color: #999;
        padding: 9px 12px;
        font-size: 0.95em;
        height: 80px !important;
      }
    }
    .MuiAutocomplete-root {
      width: 100%;
      .MuiTextField-root {
        width: 100%;
        .MuiAutocomplete-inputRoot {
          padding: 0;
          border: 1px solid #e0e0e0;
          border-radius: 5px;
          input {
            padding: 9px 12px;
            border: 0;
          }
          .MuiChip-deletable {
            margin: 4px 4px 4px;
            border-radius: 4px;
            background: #7cdbd6;
            &.chip-red {
              background: #e54644;
            }
            .MuiChip-label {
              font-weight: 600;
              color: #fff;
            }
          }
        }
      }
    }
    &.step4 {
      label {
        min-height: 30px;
        align-items: flex-start;
        display: flex;
      }
    }
  }
}

.add-child-form-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 25px 0;
  button {
    border-radius: 100px;
    padding: 8px 25px;
    font-weight: 500 !important;
    color: $appgrey;
    text-transform: none;
    margin-right: 10px;
    background: transparent !important;
    font-size: 1.05em;
    font-family: "OpenSans" !important;
    min-width: 130px;
    box-shadow: 0 0 0 1.5px $appgrey inset !important;
    letter-spacing: -0.1px;
    &:hover {
      opacity: 0.85;
    }
    &.MuiButton-containedPrimary {
      font-weight: 600;
      background: $colorprimary !important;
      margin-right: 0;
      box-shadow: none !important;
      color: #fff;
    }
    &.greenBtn {
      font-weight: 600;
      background: $appgreen !important;
      margin-right: 0;
      box-shadow: none !important;
      color: #fff;
    }
  }
}

/* ============= Create Child Profile Success ============= */
.create-child-profile-success {
  display: flex;
  flex-direction: column;
  text-align: center;
  img {
    max-width: 200px;
    margin: 20px auto;
  }
  h1 {
    margin: 20px 0;
    letter-spacing: -0.35px;
  }
  h4 {
    font-weight: 500;
    margin: 10px 0;
    color: #000;
    font-family: "OpenSans";
    letter-spacing: -0.3px;
  }
  p {
    margin: 6px 0;
  }
  ol {
    margin: 0;
    padding: 8px 0 8px 16px;
    max-width: 362px;
    text-align: left;
    margin: 0 auto;
    li {
      line-height: 1.4;
      padding: 8px 0;
    }
  }
  .MuiButton-containedPrimary {
    align-self: flex-start;
    margin: 20px auto 15px;
    border-radius: 100px;
    font-weight: 500 !important;
    padding: 10px 20px;
    min-width: 220px;
    font-size: 1em;
    font-family: "OpenSans" !important;
  }
}

/* ============== Congrats ============ */
.congrats-msg-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.congrats-msg-container {
  .app-logo {
    max-width: 100px;
    margin-top: 30px;
  }
  .congrats-msg {
    h1 {
      margin: 40px 0 8px;
    }
    p {
      margin: 0;
    }
    img {
      margin: 35px 0;
      width: 100%;
      max-width: 280px;
    }
  }
  .MuiButton-containedPrimary {
    align-self: flex-start;
    margin: 0 0 30px;
    border-radius: 100px;
    font-weight: 500 !important;
    padding: 10px 20px;
    min-width: 280px;
    font-size: 1em;
    font-family: "OpenSans" !important;
  }
}

/* ============= View / Edit Child =========== */
.view-edit-child {
  .section-hr {
    margin: 0 -30px;
    border: 0;
    border-top: 1px solid #ddd;
  }
  .expand-collapse-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      margin-bottom: 0;
    }
    svg {
      font-size: 2em;
      color: #9e9e9e;
      cursor: pointer;
      &:hover {
        color: $appgreen;
      }
    }
  }
  h4 {
    margin: 0 0 20px;
    font-family: "fGilroy";
    font-weight: 500;
  }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 16px;
    }
  }
  .MuiInputBase-multiline {
    padding-bottom: 0;
    padding-top: 0;
    .MuiInput-inputMultiline {
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      color: $colorprimary;
      padding: 9px 12px;
      font-size: 0.95em;
      height: 80px !important;
    }
  }
  .MuiAutocomplete-root {
    width: 100%;
    .MuiTextField-root {
      width: 100%;
      .MuiAutocomplete-inputRoot {
        padding: 0;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        input {
          padding: 9px 12px;
          border: 0;
        }
      }
    }
  }
  .btn-delete-profile {
    float: left;
    flex: auto;
    a {
      font-weight: 500;
      color: $appred;
    }
  }

  .view-edit-section {
    padding: 30px 0;
    .MuiInputBase-root {
      width: 100%;
    }
    .MuiFormLabel-root {
      color: #333;
      font-size: 0.95em;
      font-family: "OpenSans";
      font-weight: 500;
      margin-bottom: 10px;
      letter-spacing: -0.2px;
    }
    &.view-edit-profile {
      // -------- Section Profile
      margin-bottom: 10px;
      padding: 0 0 30px;
      .view-edit-profile-photo {
        width: 100%;
        height: 100%;
        background: #f5f7f8;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        position: relative;
        figure {
          margin: 20px;
          width: 150px;
          height: 150px;
          border-radius: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          // position: relative;
          input[type="file"] {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 1;
            left: 0;
            top: 0;
            padding: 0;
            cursor: pointer;
            text-indent: -99999px;
          }
          img {
            border-radius: 200px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          div {
            display: block;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            height: 100%;
          }
          i {
            position: absolute;
            // color: #f5f5f5;
            font-size: 1.4em;
            color: #d1d5da;
            right: 15px;
            bottom: 15px;
          }
        }
      }
      @media screen and (max-width: 599px) {
        .grid-fields {
          order: 2;
        }
        .grid-photo {
          order: 1;
        }
      }
    }
    &.view-edit-contact {
      // -------- Section Contact
      form {
        margin-top: 20px;
      }
    }
    &.view-edit-medical {
      // -------- Section Medical
      form {
        margin-top: 20px;
        .custom-chip {
          svg {
            border-color: rgba(0, 0, 0, 0.05);
          }
        }
      }
    }
    &.view-edit-other {
      // -------- Section Other
      form {
        margin-top: 20px;
      }
    }
  }

  .device-section {
    // --------- Section Device Connected
    padding: 30px 0;
    .child-code {
      width: 100%;
      max-width: 230px;
      text-align: center;
      b {
        background: #f5f7f8;
        border-radius: 6px;
        padding: 10px 30px;
        font-size: 1.6em;
        font-family: "OpenSans";
        font-weight: 500;
        color: #333;
        display: block;
        text-align: center;
        letter-spacing: 2px;
      }
      a {
        color: #9e9e9e;
        font-family: "OpenSans";
        font-weight: 500;
        font-size: 1em;
        text-align: center;
        margin-top: 10px;
        display: inline-block;
        &:hover {
          color: $appgreen;
          text-decoration: none;
        }
      }
    }
  }
}

/* ============= External Users List =========== */
.edit-delete {
  display: flex;
  align-items: center;
  justify-content: center;
  .ticon-edit-pen,
  .ticon-trash-line {
    width: 34px;
    height: 34px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.15em;
    background: #dee9ff;
    margin: 0 5px;
    cursor: pointer;
    transition: 0.15s;
    &:hover {
      transform: scale(1.2);
    }
  }
  .ticon-trash-line {
    background: #ffe1e1;
    font-size: 1.25em !important;
    color: #e54644 !important;
  }
  .ticon-edit-pen {
    background-color: #dee9ff;
  }
}

/* =========== Create New External User ========== */
.new-external-user {
  h4 {
    font-weight: 500;
    margin: 0;
    padding: 25px 0;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .section-hr {
    margin: 35px -30px 0;
    border: 0;
    border-top: 1px solid #ddd;
  }
  .access-child-hr {
    margin-top: 28px;
  }
}

.toggle-btn {
  .MuiToggleButton-root {
    padding: 8px 25px;
    color: #9e9e9e;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    text-transform: none;
    font-family: "OpenSans";
    &:hover {
      background: #fafafa;
    }
    &.Mui-selected {
      border-color: $apporange;
      background: #fef1c8;
      color: $colorprimary;
      &:hover {
        background: #f8e8b4;
      }
    }
  }
}

.no-style {
  background: none !important;
  border: 0 !important;
  box-shadow: none !important;
}

.ImageLibrary-Listing {
  .MuiGridListTile-root {
    width: 12.5% !important;
    margin-bottom: 20px;
    padding: 0 10px !important;
    .MuiGridListTile-tile {
      div {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        height: 96px !important;
        &.highlighted {
          background: #51d9c3 !important;
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .MuiGridListTile-root {
      width: 16.5% !important;
    }
  }
  @media screen and (max-width: 1024px) {
    .MuiGridListTile-root {
      width: 32.5% !important;
    }
  }
  @media screen and (max-width: 768px) {
    .MuiGridListTile-root {
      width: 24% !important;
    }
  }
}

.preview-box {
  padding: 0 15px;
  h5 {
    margin: 15px 0;
  }
  figure {
    background: #ededed;
    min-height: 281px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    img {
      mix-blend-mode: darken;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 1366px) {
    figure {
      min-height: 220px;
    }
  }
  @media screen and (max-width: 768px) {
    figure {
      min-height: 100%;
      padding: 30px 0;
      img {
        // width: 100% !important;
        height: auto !important;
      }
    }
  }
  .preview-box-list {
    // display: flex;
    // flex-wrap: wrap;
    height: 150px;
    overflow-y: auto;
    div {
      margin: 0 5px 11.6px 0;
      padding: 7px 23px 6.4px;
      border-radius: 16.2px;
      border: solid 1px #e0e0e0;
      color: #a4a4a4;
      font-size: 14px;
      white-space: nowrap;
      // height: 19px;
      height: auto;
      display: inline-block;
    }
    @media screen and (max-width: 1366px) {
      div {
        font-size: 12px;
        padding-left: 19px;
        padding-right: 19px;
      }
    }
    @media screen and (max-width: 768px) {
      div {
        padding: 10px 25px;
        margin: 0 0 10px 0;
        white-space: normal;
        display: flex;
        width: auto;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .preview-box-list {
      margin-bottom: 15px;
      div {
        margin-right: 10px;
      }
    }
  }
  .buttons-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    left: 0;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: center;
  }
  @media screen and (max-width: 1366px) {
    .buttons-footer {
      button {
        min-width: 100px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .buttons-footer {
      flex-flow: row;
      padding: 0 15px !important;
      width: 20% !important;
      bottom: 20px !important;
      margin: 0 auto;
      position: static;
      justify-content: end;
      margin: 0 !important;
      padding: 0 !important;
      button {
        padding: 3px 23px;
        margin: 0;
        min-width: 100%;
        margin-right: 10px;
      }
    }
  }
}

.ImageLib-Select {
  .MuiSelect-select.MuiSelect-select {
    padding-right: 50px;
    color: #9e9e9e;
  }
}

.ImageLib-Search {
  .MuiInputAdornment-positionStart {
    margin-right: -30px;
    position: relative;
  }
  .MuiInputBase-input {
    color: #9e9e9e;
    padding-left: 30px;
    background-color: #f8f8f8;
    border-color: #f8f8f8;
  }
  svg {
    color: #9e9e9e;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f8f8f8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #9e9e9e;
  background: #9e9e9e;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #103170;
  border-radius: 2px;
}

.AddNew-Image {
  position: relative;
  .MuiInput-root {
    position: absolute;
    right: 0;
    opacity: 0;
  }
  a {
    font-weight: 600;
  }
  .MuiInput-root:hover + a {
    color: #000000;
  }
}

.AddPopup-Box {
  h2 {
    font-weight: bold;
  }
  .MuiDialog-paperWidthMd {
    width: 100%;
  }
  .c-AddPopupBox {
    figure {
      background: #ededed;
      width: 100%;
      height: 400px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      margin-bottom: 40px;
      img {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }
    }
    .PopupRightSide {
      label {
        color: $colorprimary;
        font-size: 18px;
      }
      input[type="text"] {
        border-radius: 10px;
        border: solid 1px #e0e0e0;
        color: #9e9e9e;
        font-size: 16px;
      }
      .MuiInput-root {
        width: 100%;
        margin-bottom: 0;
      }
      .CategoriesCheckboxes {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        .MuiFormControlLabel-root {
          align-items: center !important;
          margin-right: 0;
          width: 33%;
          .MuiCheckbox-root {
            color: #e0e0e0;
          }
          .PrivateSwitchBase-root-6 {
            padding-right: 5px;
          }
          .MuiCheckbox-colorSecondary {
            &.Mui-checked {
              color: #02c8a7 !important;
              padding-right: 5px;
            }
          }
          .MuiTypography-body1 {
            font-size: 0.8rem;
            color: #333333;
            font-family: "OpenSans";
          }
        }
      }
    }
    .AddPopup-Footer {
      padding-top: 90px;
      display: flex;
      justify-content: flex-end;
    }
    @media screen and (max-width: 1366px) {
      .AddPopup-Footer {
        padding-top: 0;
      }
    }
    @media screen and (max-width: 768px) {
      .AddPopup-Footer {
        .buttons-footer {
          button {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
    .imgCropContainer {
      height: 405px;
      position: relative;
    }
    @media screen and (max-width: 1366px) {
      .imgCropContainer {
        height: 385px;
      }
    }
  }
  .slider {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .c-slider {
      max-width: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      svg {
        color: #9e9e9e;
      }
      svg:first-child {
        width: 20px;
        height: 20px;
      }
      .MuiSlider-root {
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
      }
      .MuiSlider-rail {
        background: #e0e0e0;
      }
      .MuiSlider-track {
        height: 1px;
        background: #e0e0e0;
      }
      .MuiSlider-thumb {
        width: 20px;
        height: 20px;
        object-fit: contain;
        box-shadow: 0 2px 4px 0 #bbbbbb;
        border: solid 1px #02c8a7;
        background-color: #ffffff;
      }
    }
  }
}

.activity-section {
  h3 {
    font-weight: 500;
    margin-top: 0;
    span {
      color: #a4a4a4;
      font-style: italic;
      font-size: 20px;
      letter-spacing: 0.51px;
    }
  }
  @media screen and (max-width: 1024px) {
    h3 {
      font-size: 18px;
    }
  }
  label {
    color: $colorprimary;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .form-field {
    .MuiInputBase-root {
      width: 100%;
      margin: 8px 0 5px 0;
      input {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }
  .ImgBox {
    background: $lightgrey;
    border-radius: 10px;
    height: 422px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 15px;
    .m-icon {
      position: absolute;
      width: 85px;
      height: 85px;
      object-fit: contain;
    }
    .camera-icon {
      position: absolute;
      right: 20px;
      top: 20px;
      background: #dee9ff;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;
      svg {
        font-size: 22px;
        color: $colorprimary;
      }
    }
    .selectImageBtn {
      position: absolute;
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      background: #103170;
      border-radius: 25.5px;
      padding: 7px 39px;
      text-transform: capitalize;
      &:hover {
        background: #000000;
      }
    }
  }
  .colorChanger {
    display: flex;
    margin-top: 15px;
    .cc-oval {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: 2px solid transparent;
      cursor: pointer;
      margin-right: 10px;
    }
    .cc-oval-selected {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      span {
        width: 44px;
        height: 44px;
        position: relative;
        border-radius: 100%;
        border: solid 3px #ffffff;
        left: 0;
        top: 0;
      }
    }
  }
  .activityTask {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .form-field {
      width: 100%;
      label {
        margin-bottom: 5px;
      }
    }
    img {
      margin-right: 15px;
      width: 72px;
      height: 72px;
      border-radius: 100%;
      cursor: pointer;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 1024px) {
    .activityTask {
      flex-flow: column;
      img {
        margin: 0 0 10px 0;
      }
      .form-field {
        text-align: center;
      }
    }
  }
  .addBtn {
    display: flex;
    justify-content: flex-end;
    button {
      background: $appgreen;
      border-radius: 25.5px;
      color: #ffffff;
      padding: 7px 85px;
      font-size: 18px;
      text-transform: capitalize;
    }
  }
  .sub-tasks-list-added {
    h4 {
      font-size: 18px;
      font-weight: 500;
    }
    img {
      width: 51px;
      height: 51px;
      // border-radius: 100%;
      margin-right: 20px;
      object-fit: contain;
    }
    span {
      font-size: 18px;
      color: #333333;
      flex-grow: 1;
    }
    .arrow-btn {
      min-width: 42px;
      min-height: 42px;
      background: #f0f0f0;
      border-radius: 100%;
      padding: 0 !important;
      margin: 0 10px;
      &:hover {
        background: #babacc;
      }
    }
    .scroll-div {
      overflow-y: scroll;
      height: 170px;
      padding-right: 10px;
    }
    &.height-auto {
      min-height: auto;
      padding: 0 0 15px 0;
    }
    &.height-one {
      min-height: auto;
      .scroll-div {
        height: auto;
        padding-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .sub-tasks-list-added {
      text-align: center;
      span {
        font-size: 16px;
      }
    }
  }
  .activityFooterBtns {
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
    display: flex;
    border-top: solid 1px #d6d2d2;
    margin-top: 30px;
    @media screen and (max-width: 1024px) {
      justify-content: center;
      .buttons-footer {
        flex-direction: column-reverse;
        gap: 15px;
        button {
          margin-bottom: 10px;
        }
      }
    }
  }
  .generic-card {
    padding-top: 0;
    padding-bottom: 0;
  }
  .activity-listing-two-box-container {
    display: flex;
    justify-content: space-between;
    .col-left {
      width: 70%;
      border-right: solid 1px #e4e4e4;
      padding-right: 31px;
      padding-top: 30px;
      padding-bottom: 30px;
      box-sizing: border-box;
    }
    .col-right {
      width: 35.9%;
      padding: 30px 0 30px 30px;
      position: relative;
    }
  }
}

.viewActivityListing {
  table {
    tr {
      th {
        &:first-child {
          padding-left: 20px !important;
          padding-right: 0 !important;
          width: 1%;
          svg {
            width: 26.39px;
            height: 26.39px;
          }
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          &:first-child {
            padding-left: 20px !important;
            padding-right: 0 !important;
            width: 1%;
          }
        }
      }
    }
  }
  .row-highlighted {
    background: #eff5ff;
  }
  .MuiTableContainer-root {
    width: auto;
    padding: 0;
    .MuiTable-root {
      .PrivateSwitchBase-root-1 {
        padding: 0;
      }
      .jss1 {
        padding: 0;
      }
      .MuiCheckbox-root {
        input {
          & + .MuiSvgIcon-root {
            fill: #a4a4a4;
            font-size: 1em;
            fill-opacity: 0.6;
          }
          &:checked + .MuiSvgIcon-root {
            fill: #02c8a7;
          }
        }
      }
    }
  }
  table {
    tr {
      td {
        cursor: pointer;
      }
    }
  }
  span {
    padding: 0 !important;
  }
}

.homeIcon {
  width: 43px;
  height: 43px;
  background: $applightblue;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.bellIcon {
  width: 43px;
  height: 43px;
  // background: $applightblue;
  background: #eff5ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  i {
    color: $colorprimary !important;
  }
}

.noActivityListing {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  img {
    margin-bottom: 30px;
    width: 265px;
  }
  span {
    color: $appgrey;
    font-size: 16px;
    font-weight: 600;
  }
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 30px 0;
  div {
    border: solid 1px #ededed;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    svg {
      color: #02c8a7;
      font-size: 26px;
      // padding-left: 12px;
      // padding-right: 12px;
      padding: 4px 1px !important;
      &.disabled {
        color: #9f9d9d;
        opacity: 0.5;
      }
      &.arrowbackward {
        transform: rotate(180deg);
      }
    }
    a {
      text-decoration: none;
      color: $appgrey;
      &:hover {
        text-decoration: none;
      }
      span {
        font-size: 16px;
        padding: 8px 12px !important;
        &.active {
          background: #f0fcfa;
          color: #02c8a7;
        }
      }
    }
  }
}

.schedule-screen {
  .calender-header {
    margin: 0px 0px 0px 71px;
    background: #f9fafc;
    box-shadow: 0px 2px 9px rgba(210, 208, 208, 0.117052) !important;
    border-radius: 10px 10px 0px 0px !important;
    padding: 22px 24px !important;
    height: auto;
    .arrow-btn {
      min-width: 33px;
      min-height: 40px;
      padding: 0;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      &.left-btn {
        position: absolute;
        left: 15px;
        svg {
          margin-left: 7px;
        }
      }
      &.right-btn {
        position: absolute;
        right: 15px;
      }
      svg {
        font-size: 12px;
        margin-left: 5px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .text-btn {
      margin: 0 10px 0 10px;
      background: none;
      color: #333333;
      border: 1px solid #e0e0e0;
    }
    .select-btn {
      float: right;
      margin-right: 4px;
    }
    .calendar-head-container {
      display: flex;
      .navBtns {
        display: flex;
      }
      .react-datepicker-wrapper {
        .react-datepicker__input-container {
          margin: 0 0 0 10px;
          position: relative;
          // &::before {
          //   content: "\e903";
          //   position: absolute;
          //   left: 147px;
          //   top: 12px;
          //   font-family: "fonticon" !important;
          // }
          // @media screen and (max-width: 1280px) {
          //   &::before {
          //     left: 180px;
          //   }
          // }
          input {
            background: #eef1f7 url("../../assets/images/calendar-icon-new.jpg")
              no-repeat right 10px center;
            border-radius: 4px;
            height: 40px;
            border: 0;
            text-align: center;
            font-family: "OpenSans";
            font-weight: 500;
            color: #333333;
            font-size: 16px;
            max-width: 20rem !important;
            padding-right: 40px !important;
            box-sizing: border-box !important;
            // @media screen and (max-width: 1536px) {
            //   width: 42% !important;
            // }
            // @media screen and (max-width: 1366px) {
            //   width: 200px !important;
            // }
          }
          i {
            position: absolute;
            left: 200px;
            top: 12px;
          }
        }
      }
    }
    .searchBarActive {
      display: flex;
      align-items: center;
      .searchActivityBar {
        margin: 0 0 0 10px;
        width: 50%;
        .MuiInputAdornment-positionStart {
          position: absolute;
          left: 15px;
        }
        input[type="text"] {
          padding-left: 45px;
        }
      }
      .arrow-btn {
        border: 0;
        background: none;
        svg {
          font-size: 30px;
        }
      }
    }
    // @media screen and (max-width: 1536px) {
    //   margin: 0px 0px 0px 83px;
    // }
    // @media screen and (max-width: 1366px) {
    //   margin: 0px 0px 0px 85px;
    // }
  }
  .week-days {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    position: relative;
    .gmt-container {
      position: absolute;
      left: -12px;
      top: 50%;
      display: flex;
      align-items: center;
      p {
        margin: 0;
        margin-right: 5px;
        &[title] {
          &:hover {
            &:after {
              content: attr(title);
              position: absolute;
              background: #2f2f2f;
              top: -40px;
              color: #ffffff;
              left: -30px;
              z-index: 9999;
              border-radius: 5px;
              padding: 10px 20px;
              white-space: nowrap;
              font-size: 15px;
            }
          }
        }
      }
      .gmt-ui {
        color: #a4a4a4;
        font-size: 13px;
        // display: flex;
        // margin-right: 15px;
        // margin-top: 10px;
      }
    }
    a {
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      color: #a4a4a4;
    }
    .generic-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: 94.7%;
      // width: 95.4%;
      width: 100%;
      margin: 0 0 0 71px;
      // @media only screen and (max-width: 1536px) {
      //   width: 94.24%;
      // }
      @media only screen and (max-width: 1441px) {
        // width: 92.8%;
      }
      &.week-dates-day {
        border-radius: 0 0 0 0 !important;
        background: #ffffff;
        span {
          display: block;
          text-align: center;
          &:first-child {
            font-size: 26px;
          }
          &:nth-child(2) {
            font-size: 14px;
          }
        }
        .arrow-btn {
          min-width: 38px;
          min-height: 38px;
          padding: 0;
          border-radius: 100%;
          &.left-btn {
            position: absolute;
            left: 15px;
            svg {
              margin-left: 7px;
            }
          }
          &.right-btn {
            position: absolute;
            right: 15px;
          }
          svg {
            font-size: 16px;
          }
          span {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        @media screen and (max-width: 1366px) {
          span {
            &:first-child {
              font-size: 22px;
            }
          }
        }
      }
      @media screen and (max-width: 1366px) {
        &.week-dates-day {
          padding: 14px 50px;
        }
      }
    }
    @media screen and (max-width: 1366px) {
      .generic-card {
        // width: 92.2%;
        // width: 93.5%;
      }
    }
    @media screen and (max-width: 1024px) {
      .generic-card {
        width: 89.5%;
      }
    }
    @media screen and (max-width: 991px) {
      .generic-card {
        width: 91.7%;
      }
    }
    @media screen and (max-width: 768px) {
      .generic-card {
        width: 85.5%;
      }
    }
  }
  .schedule-time-line {
    // height: 78vh;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
    box-sizing: border-box;
    // margin: 0 0 0 71px;
    @media only screen and (max-width: 1441px) {
      height: 100vh;
    }
    .rbc-time-view {
      border: 0;
      .rbc-time-header {
        display: none;
        &.rbc-overflowing {
          border: 0;
          .rbc-time-header-content {
            border: 0;
          }
        }
      }
      .rbc-timeslot-group {
        margin-bottom: 70px;
        border: 0;
        &:first-child {
          .rbc-time-slot {
            border: 0;
          }
        }
      }
      .rbc-time-content {
        border: 0;
        .rbc-time-gutter {
          text-align: right;
          .rbc-timeslot-group {
            border: 0;
            margin-bottom: 70px;
            line-height: 0;
            &:first-child {
              line-height: 1;
            }
          }
        }
      }
    }
  }

  .schedule-activity {
    h2 {
      margin: 0 0 15px 0;
    }
    p {
      margin: 0 0 15px 0;
    }
    @media screen and (max-width: 1366px) {
      padding: 15px;
      p {
        font-size: 14px !important;
      }
    }
    &.generic-card {
      border-radius: 0 0 12px 12px !important;
      & > div {
        height: 90vh;
        overflow: auto;
        overflow-x: hidden;
        padding-right: 15px;
      }
    }
    @media screen and (max-width: 1536px) {
      &.generic-card {
        & > div {
          height: 863px;
        }
      }
    }
    @media screen and (max-width: 1366px) {
      &.generic-card {
        & > div {
          height: 737px;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      &.generic-card {
        & > div {
          height: 844px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      &.generic-card {
        & > div {
          height: 99%;
          padding-right: 0;
        }
      }
    }
    p {
      color: #a4a4a4;
      font-size: 16px;
      &.Mui-error {
        color: red;
        font-size: 13px;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 1366px) {
        &.Mui-error {
          margin-bottom: 15px !important;
          font-size: 12px !important;
        }
      }
    }
    .schedule-search {
      margin-bottom: 20px;
      .MuiInput-root {
        width: 100%;
      }
      input[type="text"] {
        border-color: #f8f8f8;
        background: #f8f8f8;
        border-radius: 5px;
        padding: 12px 10px;
      }
      .MuiInputAdornment-root {
        position: absolute;
        right: 15px;
        svg {
          color: #9e9e9e;
        }
      }
      @media screen and (max-width: 768px) {
        input[type="text"] {
          font-size: 11px;
        }
        .MuiInputAdornment-root {
          svg {
            font-size: 12px;
          }
        }
      }
    }
    .schedule-activity-dragable {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 77vh;
      padding-right: 20px;
      @media screen and (max-width: 1536px) {
        height: 70vh;
      }
      @media screen and (max-width: 1366px) {
        height: 88vh;
      }
      div {
        .loader {
          border-radius: 100%;
          width: 50px;
          height: 50px;
          min-height: 50px;
        }
        div {
          min-height: 67px;
          margin-bottom: 10px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          color: #ffffff;
          padding: 15px;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
      .Mui-disabled {
        cursor: not-allowed;
      }
    }
    // @media screen and (max-width: 1366px) {
    //   .schedule-activity-dragable {
    //     height: 205px;
    //   }
    // }
    @media screen and (max-width: 1024px) {
      .schedule-activity-dragable {
        height: auto;
      }
    }
    @media screen and (max-width: 768px) {
      .schedule-activity-dragable {
        height: 460px;
        padding-right: 5px;
        div {
          div {
            flex-flow: column;
            text-align: center;
          }
        }
      }
    }
    img {
      // width: 25px !important;
      // height: 25px !important;
      width: 40px !important;
      height: 40px !important;
      border-radius: 100%;
      background: #ffffff;
      padding: 10px;
      margin-right: 15px;
    }
    @media screen and (max-width: 768px) {
      img {
        padding: 5px;
      }
    }
    .activity-cancel {
      display: none;
    }
    h3 {
      color: #333333;
      font-size: 18px;
      font-weight: 600;
    }
    .noActivityScheduleListing {
      text-align: center;
      margin-top: 30px;
      img {
        width: 100% !important;
        height: auto !important;
        margin: 0;
        padding: 0;
      }
      span {
        color: #9e9e9e;
        font-size: 16px;
        font-weight: 600;
        margin-top: 15px;
        display: block;
      }
    }
    .sa-closeIcon {
      svg {
        font-size: 20px;
        color: #747474;
        cursor: pointer;
      }
    }
    @media screen and (max-width: 1366px) {
      h2 {
        font-size: 22px;
      }
    }
    @media screen and (max-width: 768px) {
      h2 {
        font-size: 16px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .rbc-day-slot {
    box-shadow: 0 2px 9px -3px #d2d0d0 !important;
    // box-shadow: 0 2px 9px 0 rgba(210, 208, 208, 50%) !important;
    // box-shadow: 0 2px 9px 0 rgba(210 208 208 / 50%) !important;
    border-radius: 0 0 12px 12px !important;
    padding: 0;
    background: #fff;
    border: 0;
    margin-left: 0;
    // @media screen and (max-width: 1366px) {
    //   margin-left: 14px;
    // }
    .rbc-event {
      border: 0;
      background: none;
      // width: 100% !important;
      // left: 0 !important;
      // right: 0 !important;
      // margin: 0 auto;
    }
    .rbc-event-label {
      display: none;
    }
    .rbc-event-content {
      .Mui-disabled {
        svg {
          cursor: not-allowed;
        }
      }
      div {
        height: 100%;
        margin: 0;
        align-items: center;
        display: flex;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 4.78px;
        border-left: 0 !important;
        span {
          &:first-child {
            width: 46px !important;
            height: 46px !important;
            border-radius: 100%;
            background: #ffffff;
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 15px;
            img {
              width: 20px !important;
              height: 20px !important;
            }
          }
          &:nth-child(2) {
            display: flex;
            flex-grow: 1;
          }
        }
        svg {
          color: #928d8d;
          margin-right: 15px;
          position: relative !important;
          z-index: 2;
        }
        @media screen and (max-width: 1366px) {
          span {
            &:first-child {
              width: 36px !important;
              height: 36px !important;
              img {
                width: 15px !important;
                height: 15px !important;
              }
            }
          }
        }
      }
      .week-layout {
        font-size: 12px;
        font-weight: 600;
        span:first-child {
          width: 100% !important;
        }
        @media screen and (max-width: 1366px) {
          span {
            &:first-child {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 71px !important;
              border-radius: 0;
              height: auto !important;
              display: block;
              margin-left: 5px;
            }
          }
        }
      }
    }
    .rbc-time-slot {
      border-top: 1px solid #dcdcdc;
      &:nth-child(2) {
        border-color: transparent;
      }
    }
    .rbc-events-container {
      margin-right: 0;
      border-top: solid 1px gainsboro;
      margin-top: 8px;
    }
  }
  .rbc-time-slot {
    span {
      font-size: 14px;
      color: #a4a4a4;
    }
  }
  .rbc-current-time-indicator {
    display: none;
  }

  .activity-task {
    font-size: 14px;
    border-bottom: solid 1px #e7e7e7;
    padding-bottom: 15px;
    margin-bottom: 30px;
    overflow: auto;
    height: 116px;
    &.no-record {
      height: auto;
      overflow: hidden;
      padding: 0;
    }
    &.record-one {
      height: 66px;
      overflow: hidden;
    }
    @media screen and (max-width: 1024px) {
      height: 66px;
    }
    div {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    img {
      width: 51px !important;
      height: 51px !important;
      padding: 0;
      margin: 0;
      margin-right: 15px;
    }
  }
  .selectRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: flex-start;
    @media screen and (max-width: 1366px) {
      margin-bottom: 5px;
    }
    div {
      width: 100% !important;
      margin: 0;
      .MuiInput-root {
        width: 100%;
        .MuiSelect-select {
          width: 100%;
          height: 28px;
          color: #9e9e9e;
          display: flex;
          align-items: center;
        }
        input[type="text"] {
          width: 100%;
          height: 48px;
          color: #9e9e9e;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 9px 15px;
          background: url("../../assets/images/time-down-arrow.jpg") no-repeat
            right 5px center;
          cursor: pointer;
        }
      }
    }
    @media screen and (max-width: 1536px) {
      div {
        .MuiInput-root {
          input[type="text"] {
            font-size: 13px;
            // height: auto;
          }
        }
      }
    }
    @media screen and (max-width: 1366px) {
      div {
        .MuiInput-root {
          input[type="text"] {
            font-size: 14px;
            height: auto;
          }
        }
      }
    }
    label {
      font-size: 16px;
      font-weight: 600;
      color: $colorprimary;
    }
    @media screen and (max-width: 1536px) {
      label {
        font-size: 13px;
      }
    }
    @media screen and (max-width: 1366px) {
      label {
        font-size: 14px;
      }
    }
  }
  .selectRow2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    label {
      margin: 0;
      font-size: 16px;
      color: $colorprimary;
      font-weight: 600;
    }
    .MuiInputBase-root {
      width: 74%;
    }
    .MuiSelect-select {
      height: 28px;
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: 1536px) {
      label {
        font-size: 13px;
      }
      .MuiSelect-select {
        font-size: 13px;
      }
    }
    @media screen and (max-width: 1366px) {
      label {
        font-size: 14px;
      }
      .MuiSelect-select {
        font-size: 12px;
        height: auto;
      }
    }
  }
  .selectRowCheckbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    // margin-left: -9px;
    .MuiButtonBase-root {
      padding-left: 0 !important;
    }
    label {
      margin: 0;
      font-size: 16px;
      color: $colorprimary;
      font-weight: 600;
    }
    .MuiInputBase-root {
      width: 56%;
      margin-left: 15px;
    }
    .MuiSelect-select {
      height: 28px;
      display: flex;
      align-items: center;
    }
    @media screen and (max-width: 1536px) {
      label {
        font-size: 13px;
      }
      .MuiInputBase-root {
        width: 51%;
        margin-left: 10px;
      }
      .MuiSelect-select {
        font-size: 13px;
      }
    }
    @media screen and (max-width: 1366px) {
      label {
        font-size: 13px;
      }
      .MuiInputBase-root {
        width: 47%;
        margin-left: 10px;
      }
      .MuiSelect-select {
        font-size: 12px;
        height: auto;
      }
    }
    .MuiCheckbox-colorPrimary {
      &.Mui-checked {
        color: #02c8a7;
        &:hover {
          background: none;
        }
      }
    }
    .MuiIconButton-colorPrimary {
      &:hover {
        background: none;
      }
    }
  }

  .no-hover {
    &:hover {
      background: none;
    }
  }
  .selectRow3 {
    margin-bottom: 30px;
    span {
      display: block;
      font-size: 16px;
      font-weight: 600;
      color: $colorprimary;
      margin-bottom: 20px;
    }
    @media screen and (max-width: 1366px) {
      span {
        font-size: 14px;
      }
    }
    .colorChanger {
      display: flex;
      flex-wrap: wrap;
      // gap: 5px;
      // div {
      //   width: 51px !important;
      //   height: 51px !important;
      //   border-radius: 100%;
      //   cursor: pointer;
      // }
      .cc-oval {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        border: 2px solid transparent;
        cursor: pointer;
        margin-right: 5px;
        margin-bottom: 5px;
      }
      .cc-oval-selected {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        border: 2px solid black;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        margin-bottom: 5px;
        span {
          width: 34px;
          height: 34px;
          position: relative;
          border-radius: 100%;
          border: solid 3px #ffffff;
          left: 0;
          top: 0;
          margin: 0;
        }
      }
      @media screen and (max-width: 768px) {
        .cc-oval {
          width: 20px;
          height: 20px;
        }
        .cc-oval-selected {
          width: 20px;
          height: 20px;
          span {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
  .button-row {
    display: flex;
    justify-content: flex-end;
    &.button-text {
      button {
        background: #acb0b2;
        padding: 7px 24px;
        font-weight: normal;
        margin: 5px 4px;
        &:hover {
          background: #02c8a7;
        }
      }
    }
  }
  .button-row button {
    background: $colorprimary;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 25.5px;
    padding: 10px 21px;
    text-transform: inherit;
    &.Mui-disabled {
      background: gray;
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: revert;
    }
  }
  @media screen and (max-width: 1366px) {
    // .schedule-time-line {
    //   height: 65vh;
    // }
    .button-row button {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 1024px) {
    .schedule-time-line {
      height: 112vh;
    }
  }
  @media screen and (max-width: 768px) {
    .schedule-time-line {
      height: 63vh;
    }
  }
}

.termsConditionPopup {
  .MuiDialog-paperWidthMd {
    max-width: 1313px;
  }
  #alert-dialog-title {
    min-height: 90px;
    padding: 0 28px;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #dadada;
    justify-content: center;
    h2 {
      margin: 0;
      padding: 0;
      font-size: 28px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
      svg {
        cursor: pointer;
      }
    }
  }
  .MuiDialogContent-root {
    padding-left: 58px;
    padding-right: 58px;
    .c-AddPopupBox {
      .MuiGrid-item {
        margin-top: 40px;
        // height: 700px;
        // overflow-x: hidden;
        // overflow-y: scroll;
        padding-top: 0;
        padding-right: 45px;
      }
      h2 {
        color: #333333;
        font-size: 20px;
        font-weight: 500;
        margin-top: 0;
        padding-top: 0;
      }
      p {
        font-size: 18px;
        line-height: 32px;
        letter-spacing: -0.56px;
        &.Mui-error {
          font-size: 14px;
          letter-spacing: inherit;
        }
      }
      .AddPopup-Footer {
        padding-top: 0;
        .buttons-footer {
          padding: 0;
          button {
            &.grayButton {
              background: #9e9e9e !important;
              color: #ffffff;
            }
          }
        }
      }
      .tc-footer-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 90px;
        align-items: center;
        padding-bottom: 40px;
        .MuiFormControlLabel-root {
          margin: 0;
          .jss2 {
            padding-top: 0;
          }
          .PrivateSwitchBase-root-3 {
            padding: 0;
            padding-right: 10px;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #828486;
    border-radius: 4px;
  }
  @media screen and (max-width: 1366px) {
    .MuiDialog-paperWidthMd {
      max-width: 1024px;
    }
    #alert-dialog-title {
      padding: 0 5px;
    }
    .MuiDialogContent-root {
      padding-left: 30px;
      padding-right: 30px;
      .c-AddPopupBox {
        .MuiGrid-item {
          margin-top: 34px;
          // height: 350px;
          padding-right: 35px;
          @media screen and (max-width: 767px) {
            padding-right: 0;
          }
        }
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}

.scp-popup {
  .MuiDialog-paperWidthSm {
    max-width: 644px;
    width: 100%;
    .MuiDialogTitle-root {
      padding: 40px 35px;
      h2 {
        font-size: 1.6em;
        margin: 0;
        width: 100%;
        padding: 0;
      }
    }
  }
  .MuiDialog-paper {
    .MuiDialogContent-root {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
  label {
    font-size: 16px;
    font-weight: 600;
    color: $colorprimary;
    margin-bottom: 15px;
  }
  .MuiInput-root {
    width: 100%;
  }
  .field-container {
    margin-bottom: 23px;
    div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &.pl-8 {
      padding-left: 8px;
    }
    @media screen and (max-width: 800px) {
      &.pl-8 {
        padding-left: 0;
      }
    }
    &.pr-8 {
      padding-right: 8px;
    }
  }
  .MuiSvgIcon-root {
    position: absolute;
    width: 20px;
    height: 20px;
    background: $applightblue2;
    border-radius: 100%;
    &.incrementIcon {
      right: 10px;
      cursor: pointer;
    }
    &.decrementIcon {
      left: 10px;
      cursor: pointer;
      z-index: 9999;
    }
    &.popup-close {
      background: none;
      top: 35px;
      right: 20px;
    }
  }
  input[type="text"] {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 16px;
    color: $appgrey;
    &#medicationDosage {
      text-align: center;
    }
  }
  .popup-buttons-footer {
    button {
      border-radius: 25.5px !important;
      font-size: 18px !important;
      font-weight: bold !important;
      text-transform: capitalize;
      &.MuiButton-contained {
        background: none !important;
        border: solid 1px #02c8a7;
        color: #02c8a7 !important;
      }
      &.MuiButton-containedPrimary {
        background-color: #02c8a7 !important;
        color: #ffffff !important;
        margin-left: 10px;
      }
    }
  }
}

/* RESPONSIVE */

@media (max-width: 986px) {
  .AddPopup-Box {
    .c-AddPopupBox {
      .PopupRightSide {
        .CategoriesCheckboxes {
          .MuiFormControlLabel-root {
            align-items: center !important;
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .generic-card {
    h1 {
      font-size: 1.2em;
    }
  }
  .buttons-footer {
    flex-direction: column;
    gap: 10px;
  }
  .sidebar-menu {
    .sidebar-profile {
      figure {
        margin-right: 5px;
      }
      .sidebar-profile-title {
        padding: 0;
      }
    }
    .menu-list {
      .MuiListItem-button {
        // padding: 10px 35px;
        flex-wrap: wrap;
        justify-content: center;
        i {
          margin: 0;
        }
        .MuiListItemText-root {
          span {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .activity-section {
    .addBtn {
      button {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        box-sizing: border-box;
      }
    }
    h3 {
      font-size: 18px;
    }
    label {
      font-size: 14px;
    }
    .activityTask {
      flex-direction: column;
      justify-content: center;
      img {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
    .colorChanger {
      .cc-oval {
        width: 34px;
        height: 34px;
      }
      .cc-oval-selected {
        width: 34px;
        height: 34px;
        span {
          width: 26px;
          height: 26px;
        }
      }
    }
    .MuiGrid-grid-xs-4 {
      .generic-card {
        padding: 15px;
      }
    }
    .sub-tasks-list-added {
      h4 {
        font-size: 16px;
      }
      span {
        font-size: 14px;
      }
      img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
    }
  }
  .schedule-screen {
    .week-days {
      a {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 659px) {
  .AddPopup-Box {
    .c-AddPopupBox {
      .AddPopup-Footer {
        padding-top: 0;
        justify-content: center;
      }
    }
  }
}

.dummyClass {
  background-color: rgba(2, 200, 167, 0.69);
}

.add-medication-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #02c8a7;
  font-weight: 600;
  margin-top: 10px;
  cursor: pointer;
  svg {
    color: #02c8a7;
  }
}

.medicationScrollBar {
  overflow-y: scroll;
  height: 134px;
  display: flex;
  border-radius: 8px;
  gap: 10px;
  padding-top: 10px;
  flex-wrap: wrap;
  border: solid 1px #e0e0e0;
  box-sizing: border-box;
  padding: 15px;
  .medicationBox {
    background: #f4f4f4;
    border-radius: 8px;
    position: relative;
    width: 32%;
    height: 62px;
    box-sizing: border-box;
    padding: 9px 21px 10px 19px;
    margin-bottom: 10px;
    span {
      display: block;
    }
    .medicinename {
      font-weight: 600;
      font-size: 16px;
      color: #666666;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-bottom: 2px;
    }
    .description {
      font-size: 14px;
      font-weight: 400;
      color: #a4a4a4;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .closeIcon {
      position: absolute;
      right: 0;
      top: -9px;
      width: 22px;
      height: 22px;
      background: #e0e0e0;
      color: #ffffff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        font-size: 16px;
        color: #9e9e9e;
        &.Mui-disabled {
          cursor: not-allowed;
        }
      }
    }
    .editIcon {
      position: absolute;
      right: 0;
      top: 20px;
      color: #a4a4a4;
      font-size: 18px;
      cursor: pointer;
    }
    &.w-49 {
      width: 49%;
    }
    @media screen and (max-width: 1024px) {
      &.w-49 {
        width: 100%;
      }
    }
  }
}

.sa-message {
  display: flex;
  align-items: center;
  background: #eff5ff;
  padding: 14px 28px;
  border-radius: 10px;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.09);
  svg {
    width: 30px;
    height: 30px;
    margin: 0 5px 0 0;
    padding: 9px 6px 9px 7px;
    color: #02c8a7;
  }
  span {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
    &.errorIcon {
      svg {
        color: #ff0000;
      }
    }
  }
}

.timeSep {
  background: #979797;
  height: 2px;
  width: 9px;
  margin: 0 2%;
  margin-top: 49px;
}

.comingSoon {
  font-size: 9px;
  font-weight: bold;
  color: #103170;
  background: #f4f4f4;
  border-radius: 11px;
  padding: 4px 9px 5px 8px;
}

.page-not-found {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 50px;
}

/* Disabled Classes */

.MuiSelect-select.Mui-disabled {
  cursor: not-allowed !important;
  opacity: 0.9;
  background-color: #eee;
  color: #999;
}

.MuiInput-input.Mui-disabled {
  cursor: not-allowed !important;
}

textarea.MuiInput-input.Mui-disabled {
  cursor: not-allowed !important;
  opacity: 0.9;
  background-color: #eee;
  color: #999;
}

.Mui-disabled {
  .add-medication-btn {
    cursor: not-allowed !important;
    opacity: 0.9;
    color: #999;
    svg {
      cursor: not-allowed !important;
      opacity: 0.9;
      color: #999;
    }
  }
  &.camera-icon {
    cursor: not-allowed !important;
  }
  &.imgBlock {
    cursor: not-allowed !important;
  }
  &.linkBlock {
    cursor: not-allowed !important;
    pointer-events: revert !important;
  }
  &.noColorChange {
    cursor: not-allowed !important;
    &.cc-oval {
      border: 0 !important;
    }
    &.cc-oval-selected {
      border: 0 !important;
      span {
        display: none;
      }
    }
  }
}

.MuiButtonBase-root {
  &.Mui-disabled {
    &.btn-rounded {
      cursor: not-allowed !important;
      pointer-events: revert;
    }
  }
}

.activity-section {
  .addBtn {
    button {
      &.MuiButton-root:hover {
        &.Mui-disabled {
          background: #02c8a7 !important;
        }
      }
    }
  }
}

.MuiButton-label {
  text-transform: none;
}

.selfRegulationListing {
  table {
    tr {
      th {
        &:first-child {
          padding-left: 20px !important;
          padding-right: 0 !important;
          width: 1%;
          svg {
            width: 26.39px;
            height: 26.39px;
          }
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          &:first-child {
            padding-left: 20px !important;
            padding-right: 0 !important;
            width: 1%;
          }
        }
      }
    }
  }

  .MuiTableContainer-root {
    width: auto;
    input {
      & + .MuiSvgIcon-root {
        fill: #a4a4a4;
        font-size: 1em;
        fill-opacity: 0.6;
      }
      &:checked + .MuiSvgIcon-root {
        fill: #02c8a7;
      }
    }
  }
  .row-highlighted {
    background: #eff5ff;
  }
  .PrivateSwitchBase-root-1 {
    padding: 0;
  }
  .jss5 {
    padding: 0;
  }
  span {
    padding: 0 !important;
  }
}

.archiveIcon {
  background: #f0f0f0;
  width: 41px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin: 0 auto;
  cursor: pointer;
  svg {
    color: #9e9e9e;
    height: 33px;
    width: 33px;
  }
  @media screen and (max-width: 1366px) {
    svg {
      height: 26px;
      width: 26px;
    }
  }
}

.activateThisTool {
  h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 3px;
  }
  p {
    margin: 0;
    margin-bottom: 20px;
    color: #a4a4a4;
  }
  .MuiFormControl-root {
    width: 100%;
    label {
      display: flex;
      align-items: center !important;
    }
    .MuiFormGroup-root {
      display: flex;
      flex-wrap: wrap;
      flex-flow: row;
    }
    @media screen and (max-width: 1024px) {
      .MuiFormGroup-root {
        flex-flow: column;
      }
    }
    .MuiRadio-colorSecondary {
      &.Mui-checked {
        color: blue;
      }
    }
  }
}

.buttons-head {
  margin-bottom: 25px;
  span {
    width: 148px;
    height: 36px;
    margin: 0 7px 0 0;
    padding: 0;
    object-fit: contain;
    border-radius: 18px;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    line-height: 0;
    &.active {
      border: solid 1px #02c8a7;
      background-color: #e7fffb;
      color: #02c8a7;
    }
    &.inactive {
      border: solid 1px #02c8a7;
      color: #02c8a7;
    }
  }
}

.self-regulation-rs {
  .sub-tasks-list-added {
    min-height: 196px;
    margin-top: 20px;
    border-bottom: solid 1px #d1cccc;
    margin-bottom: 18px;
    h4 {
      margin: 0;
      margin-bottom: 5px;
    }
    span {
      font-size: 16px;
    }
    .arrow-btn {
      min-width: 42px;
      min-height: 42px;
      background: #f0f0f0;
      border-radius: 100%;
      padding: 0 !important;
      margin: 0 10px;
    }
  }
  .activityFooterBtns {
    position: static;
  }
  .activateThisTool {
    h4 {
      font-size: 16px;
      letter-spacing: 0.5px;
    }
    .MuiFormControl-root {
      .MuiFormControlLabel-label {
        color: #9e9e9e;
      }
      .MuiRadio-colorSecondary {
        &.Mui-checked {
          color: #02c8a7;
          ~ .MuiFormControlLabel-label {
            color: #02c8a7;
          }
        }
      }
      @media screen and (max-width: 1366px) {
        .MuiFormControlLabel-label {
          // font-size: 0.9rem;
          font-size: 13px;
        }
      }
    }
  }
}

.self-regulation-section {
  .enableCheck {
    color: #9e9e9e;
    font-size: 16px;
    cursor: pointer;
    .MuiCheckbox-root {
      color: transparent;
    }
    svg {
      width: 20px;
      height: 20px;
      border-radius: 2.5px;
      border: solid 1.2px #e0e0e0;
    }
    .MuiCheckbox-colorSecondary {
      &.Mui-checked {
        color: #02c8a7 !important;
      }
    }
  }
  .generic-card {
    padding-top: 0;
    padding-bottom: 0;
  }
  .self-reg-two-box-container {
    display: flex;
    justify-content: space-between;
    .col-left {
      width: 70%;
      border-right: solid 1px #e4e4e4;
      padding-right: 31px;
      padding-top: 30px;
      padding-bottom: 30px;
      box-sizing: border-box;
    }
    .right-col {
      padding-top: 30px;
      display: none;
    }
    .col-right {
      width: 35.9%;
      padding: 30px 0 30px 30px;
    }
    @media screen and (max-width: 1366px) {
      .col-left {
        padding-right: 30px;
      }
      .col-right {
        padding-left: 30px;
      }
    }
  }
}

.snd-popup {
  .MuiDialogTitle-root {
    padding: 39px 24px 16px 24px;
  }
  .MuiDialog-paperWidthXs {
    h2 {
      font-size: 24px;
      letter-spacing: -0.75px;
      color: $colorprimary;
      margin-bottom: 18px;
      font-weight: 700;
    }
    max-width: 644px;
    width: 100%;
    .MuiFormLabel-root {
      color: $colorprimary;
      font-size: 16px;
      letter-spacing: 0.5px;
      margin-bottom: 18px;
    }
    .MuiInput-root {
      width: 95%;
      margin-bottom: 25px;
    }
    .MuiSelect-select {
      border-radius: 10px;
      border: solid 1px #e0e0e0;
      background: #ffffff;
      height: 48px;
      font-size: 16px;
      color: #9e9e9e;
      padding: 0;
      line-height: 48px;
      padding-left: 15px;
    }
    .selectDays {
      display: flex;
      div {
        width: 51px;
        height: 51px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-right: 5px;
      }
    }
    .MuiDialog-paper {
      .popup-close {
        width: 24px;
        margin: 17px 15px 0 0;
      }
    }
  }
  .MuiButton-containedPrimary {
    background: #02c8a7 !important;
    width: 101px;
    height: 51px;
    span {
      font-size: 18px;
    }
  }
  .popup-close {
    width: 28px;
    height: 28px;
    fill: #999;
    float: right;
    position: absolute;
    right: 0;
    margin: 37px 35px 0 0;
    cursor: pointer;
  }
  @media screen and (max-width: 1366px) {
    .MuiDialogTitle-root {
      padding-top: 20px;
    }
  }
}

.boxContainer {
  border-radius: 8px;
  border: solid 1px #e0e0e0;
  max-width: 100%;
  width: 100% !important;
  margin: auto !important;
  box-sizing: border-box;
  padding: 0 !important;
  min-height: 335px;
  .MuiGrid-grid-md-6 {
    padding: 0 !important;
    &:first-child {
      h4 {
        background: #f6f7f9;
        border-radius: 8px 0 0 0;
        padding-left: 40px;
      }
    }
    &:nth-child(2) {
      h4 {
        background: #f6f7f9;
        border-radius: 0 8px 0 0;
        @media screen and (max-width: 959px) {
          padding-left: 40px;
          margin: 0;
        }
      }
      @media screen and (max-width: 768px) {
        h4 {
          padding-left: 40px;
        }
      }
    }
  }
  h4 {
    min-height: 84px;
    display: flex;
    align-items: center;
  }
  .planDetails {
    padding-left: 40px;
    h3 {
      font-weight: bold;
      color: #02c8a7;
      font-size: 18px;
      margin: 0;
      margin-bottom: 10px;
    }
    p {
      margin: 0;
      &.price {
        font-weight: 600;
        font-size: 20px;
        color: #333333;
        margin-bottom: 10px;
      }
    }
  }
  .buttons-footer {
    padding-left: 40px;
    display: block;
    button {
      &.cancelBtn {
        color: red !important;
        box-shadow: none !important;
        font-size: 18px;
        padding-left: 0;
      }
    }
  }
}

.archive-Selection {
  margin-bottom: 25px;
  button {
    width: 191px;
    height: 40px;
    padding: 0;
    border: solid 1px #a4a4a4;
    background-color: #f8f8f8;
    font-size: 14px;
    line-height: 14px;
    color: #a4a4a4;
    letter-spacing: 0;
    span {
      div {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
    &:hover {
      background-color: #f5f3f3;
    }
  }
}

.actArcMessage {
  height: 78px;
  border-radius: 10px;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.09);
  background-color: #eff5ff;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 23px 24px 22px;
  svg {
    font-size: 30px;
    color: #02c8a7;
    margin-right: 10px;
    &.closeIcon {
      color: #9e9e9e;
      font-size: 22px;
      margin: 0;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  span {
    font-size: 24px;
    color: #103170;
    line-height: 18px;
    letter-spacing: -0.72px;
    flex-grow: 1;
    &.undo {
      flex-grow: 0;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: -0.6px;
      color: #333333;
      cursor: pointer;
      margin-right: 15px;
      margin-left: 15px;
    }
  }
  &.error-message {
    .errorIcon {
      svg {
        color: #ff0000;
      }
    }
  }
  @media screen and (max-width: 1366px) {
    height: 68px;
    padding: 13px 15px 12px;
    svg {
      font-size: 25px;
      &.closeIcon {
        font-size: 18px;
      }
    }
    span {
      font-size: 20px;
      &.undo {
        font-size: 18px;
      }
    }
  }
}

.wi-left {
  margin: 0 30px 0 0;
  figure {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.wi-right {
  font-size: 18px;
  @media screen and (max-width: 1536px) {
    font-size: 14px;
  }
  .watch-status {
    margin-bottom: 10px;
    span {
      color: $appgreen;
      font-weight: 600;
    }
  }
  .date-synced {
    margin-bottom: 10px;
    span {
      font-weight: 600;
    }
  }
  .device-info {
    margin-bottom: 10px;
    span {
      font-weight: 600;
    }
  }
  .logout-watch {
    color: #e54644;
    font-weight: 700;
  }
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
}

.child-code {
  text-align: center;
  .c--childcode {
    width: 100%;
    display: block;
    padding: 0;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #f5f7f8;
    font-size: 28px;
    margin-bottom: 40px;
    min-height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a {
    color: #9e9e9e;
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    .c--childcode {
      padding: 10px 0;
      font-size: 20px;
    }
  }
}

.signin-to-your-account {
  justify-content: center;
  margin-top: 15px;
  a {
    color: $colorprimary;
    font-size: 0.9em;
    font-weight: 500;
    margin-top: 20px;
    span {
      color: #787474;
      font-weight: 400;
    }
  }
}

.forgotpassword {
  h1 {
    position: relative;
    padding-left: 40px;
    span {
      position: absolute;
      left: 0;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }
}

.r-select-days {
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 1366px) {
    margin-top: 10px;
  }
  label {
    color: $colorprimary;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
    &#repeatEveryLabel {
      input[type="number"] {
        width: 75px;
        height: 45px;
        background: #f1f3f4;
        text-align: center;
        border: 0;
        border-radius: 10px;
        margin-left: 15px;
        margin-right: 15px;
        &:focus {
          outline: none;
        }
      }
    }
    &#neverLabel {
      display: flex;
      align-items: center;
      input[type="radio"] {
        margin-right: 10px;
      }
    }
    &#onLabel {
      display: flex;
      align-items: center;
      input[type="radio"] {
        margin-right: 10px;
      }
      .react-datepicker-wrapper {
        margin-left: 40px;
      }
    }
    &#afterLabel {
      display: flex;
      align-items: center;
      input[type="radio"] {
        margin-right: 10px;
      }
      input[type="number"] {
        // background-color: #eee;
        // color: #999;
        border: 1px solid #e0e0e0;
        border-radius: 9px;
        font-size: 0.95em;
        padding: 9px 0 !important;
        width: 70px;
        margin: 0 15px 0 25px;
        text-align: center;
      }
      label {
        margin-bottom: 0;
      }
    }
  }
  .selectDays {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-bottom: 30px;
    @media screen and (max-width: 800px) {
      justify-content: flex-start;
      margin-bottom: 10px;
    }
    div {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-right: 5px;
      @media screen and (max-width: 800px) {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 1024px) {
      div {
        margin-bottom: 5px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

#notifications-menu {
  .MuiPopover-paper {
    width: 473px;
    height: 590px;
    border-radius: 8px;
    box-shadow: 0 7px 25px 1px rgba(63, 63, 68, 0.07);
    border: solid 1px #dadada;
    background-color: #f9fafc;
    right: 11% !important;
    left: auto !important;
    top: 7% !important;
    overflow: visible;
    .notify-box {
      h4 {
        margin: 0;
        color: #333333;
        font-size: 16px;
      }
      h5 {
        margin: 0;
        font-size: 24px;
        min-height: 60px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        // opacity: 0.23;
        border-bottom: solid 1px #e2e3e5;
      }
      p {
        font-size: 14px;
        color: #333333;
        margin: 0;
        margin-bottom: 10px;
        width: 291px;
        &.notification-time {
          color: #e54644;
          letter-spacing: -0.39px;
          margin: 0;
        }
        &.notification-time-gray {
          color: #a4a4a4;
        }
      }
      img {
        width: 46px;
        height: 46px;
        border-radius: 100%;
        margin-right: 20px;
        object-fit: cover;
      }
      .txt-container {
        flex-grow: 1;
      }
      .reddot {
        margin-right: 20px;
      }
      .notificationsScroll {
        height: 480px;
        overflow-y: auto;
        margin: 30px 20px 0 20px;
        .row {
          margin-bottom: 45px;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .MuiPopover-paper {
      right: 16% !important;
      top: 10% !important;
      width: 440px;
      height: 480px;
      .notify-box {
        h4 {
          font-size: 14px;
        }
        h5 {
          font-size: 20px;
          min-height: 40px;
        }
        p {
          font-size: 13px;
        }
        img {
          width: 36px;
          height: 36px;
        }
        .notificationsScroll {
          height: 415px !important;
          margin-top: 15px !important;
          .row {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .MuiPopover-paper {
      right: 20% !important;
      top: 5% !important;
    }
  }
  @media screen and (max-width: 768px) {
    .MuiPopover-paper {
      right: 30% !important;
      top: 7% !important;
    }
  }
}
.nocursor {
  cursor: default !important;
}

.disabled-icon {
  cursor: not-allowed !important;
}

.profile-code-container {
  box-sizing: border-box;
  position: absolute;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
}

.pos-relative {
  position: relative;
}

.section-hide {
  display: none !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.jss9 {
  padding: 0 !important;
}

.jss13 {
  padding: 0 !important;
}

.navBtn {
  display: none !important;
}

.sidebarClose {
  display: none;
}

@media screen and (max-width: 991px) {
  .sidebar-menu {
    .sidebar-profile {
      .sidebar-profile-title {
        flex: 1;
      }
      i {
        &.ticon-sync-off {
          flex: 0;
        }
      }
    }
  }
  .navBtn {
    display: block !important;
    display: block !important;
    margin-bottom: 30px !important;
    height: 50px;
    line-height: 0 !important;
    width: 43px !important;
  }
  .boxclose {
    display: none;
  }
  .boxopen {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
    height: 100%;
    width: 100%;
    .generic-card {
      width: 30%;
    }
  }
  .box-full-width {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .schedule-screen {
    .week-days {
      // justify-content: space-between;
      .generic-card {
        &.week-dates-day {
          span {
            &:first-child {
              font-size: 16px;
            }
          }
        }
      }
    }
    .rbc-day-slot {
      margin-left: 7px;
    }
    .schedule-activity {
      &.generic-card {
        & > div {
          height: auto;
        }
        @media screen and (max-width: 800px) {
          height: 840px;
        }
        @media screen and (max-width: 768px) {
          height: 640px;
        }
      }
      .schedule-activity-dragable {
        height: 600px;
      }
    }
  }
  .child-code {
    .c--childcode {
      padding: 10px 0;
      font-size: 20px;
    }
  }
  #notifications-menu {
    .MuiPopover-paper {
      right: 25% !important;
      top: 5% !important;
    }
  }
  .sidebarClose {
    margin: 0;
    color: #ffffff;
    margin-bottom: 11px;
    font-size: 0.9em;
    cursor: pointer;
    position: absolute;
    background: #cac3c3;
    border-radius: 100%;
    width: 22px;
    height: 22px;
    top: -5px;
    right: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 22px;
  }
}

@media screen and (max-width: 768px) {
  .schedule-screen {
    .week-days {
      .generic-card {
        width: 89.2%;
      }
    }
  }
  .schedule-activity {
    .schedule-activity-dragable {
      height: 472px !important;
    }
  }
}

/* PAYMENT METHOD */

.payment-method-outer {
  .grid-body {
    padding-top: 0;
    .paymentmethod {
      max-width: 1165px !important;
      .box-full-width {
        max-width: 100% !important;
        flex-basis: 100% !important;
      }
      .MuiGrid-item {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .plr-78 {
        padding-left: 78px;
        padding-right: 78px;
        .payment-method-block {
          padding-top: 20px;
        }
      }
      .sep {
        border-bottom: solid 1px #e7e7e7;
        padding-bottom: 50px;
        // margin-bottom: 50px;
        @media screen and (max-width: 1366px) {
          padding-bottom: 27px;
          margin-bottom: 0;
        }
      }
      .MuiPaper-elevation1 {
        box-shadow: none;
      }
      h4 {
        font-weight: 500;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 1366px) {
          margin-top: 20px;
          margin-bottom: 10px;
          font-size: 20px;
        }
        span {
          color: #9e9e9e;
          font-size: 16px;
        }
      }
      .radio-block {
        .MuiFormControlLabel-root {
          max-width: 186px;
          & > span {
            p {
              figure {
                margin-bottom: 0;
              }
            }
            span {
              color: #333333;
              letter-spacing: -0.44px;
              font-size: 16px;
            }
          }
          .MuiRadio-root {
            .Mui-checked {
              & + span {
                p {
                  strong {
                    color: #9e9e9e;
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }
      .select-payment-plan {
        padding: 10px 80px;
        h4 {
          font-weight: 500;
          font-size: 24px;
          margin-bottom: 30px;
          @media screen and (max-width: 1366px) {
            font-size: 20px;
          }
          span {
            color: #9e9e9e;
            font-size: 16px;
          }
        }
        .radio-block {
          .MuiFormControlLabel-root {
            max-width: 256px;
            height: 178px;
            @media screen and (max-width: 1366px) {
              height: 156px;
            }
            span {
              &.savetag {
                width: 75px;
                height: 25px;
                background: #f9be02;
                font-size: 12px;
                letter-spacing: -0.33px;
                color: #333333;
                position: absolute;
                left: 13px;
                top: 18px;
                border-radius: 12.5px;
                justify-content: center;
                display: flex;
                align-items: center;
                font-weight: 600;
              }
              &.billed-dur {
                color: #a4a4a4;
                font-size: 14px;
                letter-spacing: -0.39px;
              }
            }
            & > span {
              &:last-child {
                p {
                  strong {
                    font-size: 31px;
                    color: #333333;
                  }
                }
              }
            }
            .MuiRadio-root {
              &.Mui-checked {
                & + span {
                  p {
                    background: #f4fcfb;
                    strong {
                      color: #02c8a7 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.change-payment-plan {
  .MuiDialog-paper {
    &.MuiDialog-paperWidthXs {
      max-width: 672px;
      width: 100%;
      min-height: 372px;
      .MuiDialogContent-root {
        flex: 0;
      }
    }
  }
  .MuiDialog-paper {
    h2 {
      font-size: 1.5rem;
    }
    .MuiDialogActions-root {
      flex-direction: column;
    }
  }
  .radio-block {
    .MuiFormControlLabel-root {
      max-width: 256px;
      height: 178px;
      @media screen and (max-width: 1366px) {
        height: 156px;
      }
      @media screen and (max-width: 767px) {
        max-width: 100%;
        margin: 0;
        margin-bottom: 20px;
      }
      span {
        &.savetag {
          width: 75px;
          height: 25px;
          background: #f9be02;
          font-size: 12px;
          letter-spacing: -0.33px;
          color: #333333;
          position: absolute;
          left: 13px;
          top: 18px;
          border-radius: 12.5px;
          justify-content: center;
          display: flex;
          align-items: center;
          font-weight: 600;
        }
        &.billed-dur {
          color: #a4a4a4;
          font-size: 14px;
          letter-spacing: -0.39px;
        }
      }
      & > span {
        &:last-child {
          p {
            strong {
              font-size: 31px;
              margin-bottom: 15px;
              color: #333333;
            }
          }
        }
      }
      .MuiRadio-root {
        &.Mui-checked {
          & + span {
            p {
              background: #f4fcfb;
              strong {
                color: #02c8a7 !important;
              }
            }
          }
        }
      }
    }
  }
  .goback-btn {
    background: none !important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #9e9e9e;
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .MuiButton-containedPrimary {
    font-weight: bold !important;
    font-size: 16px !important;
    padding: 11px 28px;
  }
}

.MuiInputAdornment-root {
  &.MuiInputAdornment-positionEnd {
    position: absolute;
    right: 0;
    button {
      &.MuiIconButton-root {
        &:hover {
          background: none !important;
        }
      }
    }
  }
}

.dashboard-first-block {
  position: relative;
  padding: 40px 60px;
  box-sizing: border-box;
  border: solid 1px #dcdcdc;
  border-radius: 8px;
  z-index: 1;
  margin-top: 20px;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 8px;
  }
  .warning-popup {
    position: absolute;
    z-index: 9999;
    top: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-flow: column;
    width: 567px;
    padding: 50px 45px;
    object-fit: contain;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 22px 24px 5px rgba(218, 218, 218, 0.5);
    border: solid 1px #ffffff;
    background-color: rgba(255, 255, 255, 0.59);
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    p {
      font-size: 24px;
      text-align: center;
      font-weight: 500;
      color: #333333;
    }
    button {
      margin: 0;
      padding: 16px 54px 17px 55px;
      object-fit: contain;
      border-radius: 35.5px;
      background-color: #103170 !important;
      font-weight: bold !important;
      font-size: 18px;
    }
  }
}

#profile-menu {
  .MuiMenu-list {
    height: auto !important;
    padding: 10px 0;
    li {
      color: #333333;
      background: none;
      padding: 0;
      padding-top: 10px;
      display: flex;
      align-items: center;
      padding-left: 15px;
      font-size: 14px;
      padding-bottom: 10px;
      &:hover {
        background: none;
        color: #e54644;
      }
      i {
        margin-right: 15px;
        font-size: 16px;
      }
    }
  }
  .MuiPopover-paper {
    width: 193px;
    margin: 0;
    padding: 0;
    object-fit: contain;
    border-radius: 8px;
    // box-shadow: 0 7px 25px 1px rgb(63 63 68 / 15%);
    border: solid 1px #dadada;
    background-color: #f9fafc;
    top: 63px !important;
    right: 31.5px !important;
    left: auto !important;
  }
}

.card-details {
  display: flex;
  align-items: center;
  .cardIcon {
    margin-right: 15px;
  }
  p {
    margin-right: 15px;
  }
  @media screen and (max-width: 959px) {
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 0px;
  }
}

.logout-box {
  z-index: 9999 !important;
}

.e-reminders {
  position: relative;
  margin: 0;
  margin-left: 5px;
  button {
    background: none !important;
    padding: 0;
  }
  &[title] {
    &:hover {
      &:after {
        content: attr(title);
        position: absolute;
        background: #2f2f2f;
        top: -40px;
        color: #ffffff;
        left: -30px;
        z-index: 9999;
        border-radius: 5px;
        padding: 10px 20px;
        white-space: nowrap;
        font-size: 15px;
        @media screen and (max-width: 1440px) {
          left: -100px;
        }
        @media screen and (max-width: 1366px) {
          left: auto;
          right: -104px;
        }
      }
    }
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.carousel {
  // padding: 10px 10px 0;
  width: 100% !important;
  height: 95vh;
  margin: auto;
  background-size: contain;
  // background-color: #fff !important;
  background-position: center center;
}

.carousel__main {
  height: 100vh;
}

.carousel__item {
  width: 100% !important;
  height: 527px !important;
  background-size: contain !important;
  margin-top: 80px;
  @media screen and (max-width: 1536px) {
    height: 417px !important;
    margin-top: 20px;
  }
  @media screen and (max-width: 1366px) {
    height: 300px !important;
    margin-top: 15px;
  }
  h1 {
    color: #000000;
    text-align: center;
    font-size: 37px;
    font-weight: 400;
    line-height: 43.36px;
    margin: 36rem 0 1rem 0;
    @media screen and (max-width: 1536px) {
      margin: 28rem 0 1rem 0;
    }
    @media screen and (max-width: 1366px) {
      font-size: 27px;
      margin: 21rem 0 0 0;
    }
  }
  p {
    color: #000000;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    max-width: 30rem;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 1366px) {
      font-size: 14px;
      max-width: 23rem;
    }
  }
}

// .demo-item {
//   height: 527px;
// }

.carousel__dot {
  width: 73px !important;
  height: 5px !important;
  background-color: #c4c4c4 !important;
  border-radius: 0 !important;
  cursor: pointer;
}

.carousel__dot--active {
  background-color: #02c8a7 !important;
}

.ImageLib-Select-dropdown {
  display: flex;
  button {
    border: 1px solid #e0e0e0;
    padding: 7px 15px 6px 15px;
    background: none;
    border-radius: 9px;
    font-size: 15.2px;
    color: #a2a1a1;
    font-family: "OpenSans";
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;
    > div {
      width: 100% !important;
      display: flex;
      align-items: center;
      div {
        margin: 1px 6px 0 0;
        width: 18px !important;
        height: 18px !important;
        border: solid 1px #cccccc;
      }
    }
    svg {
      font-size: 24px;
      margin: 0;
      color: rgba(0, 0, 0, 0.54);
    }
  }
  .circle-picker {
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
    background: #ffffff;
    border-radius: 14px;
    margin: 10px 0 0 20px;
    align-items: center;
    justify-content: center;
    padding: 15px 15px;
    width: auto !important;
    @media screen and (max-width: 1366px) {
      width: auto !important;
      // padding-right: 20px;
    }
    span {
      &:first-child {
        div {
          div {
            border: solid 1px #cccccc;
            border-radius: 100% !important;
          }
        }
      }
      div {
        width: 23px !important;
        height: 23px !important;
        margin: 0 13px 0 0 !important;
        @media screen and (max-width: 1366px) {
          width: 18px !important;
          height: 18px !important;
          // margin: 14px 6px !important;
        }
      }
    }
  }
}

.ocBtn {
  button {
    padding: 0;
    position: absolute;
    left: 0;
    background: #f9fafc;
    width: 100%;
    bottom: 0;
    height: 90px;
    border-radius: 0 0 12px 12px;
    span {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background: #ececed;
        width: 100%;
        height: 2px;
        left: 0;
        top: 50%;
      }
      span {
        &::before {
          display: none;
        }
        &.cbox {
          background: url("../../assets/images/arrow-btn-ec.png") no-repeat
            center center;
          width: 36px;
          height: 36px;
        }
        &.obox {
          background: url("../../assets/images/arrow-btn-ec.png") no-repeat
            center center;
          width: 36px;
          height: 36px;
          transform: rotate(180deg);
        }
      }
    }
  }
}

.searchActivity {
  margin-right: 10px;
  .arrow-btn {
    min-width: 43px !important;
    svg {
      font-size: 24px !important;
    }
  }
  .MuiInputAdornment-positionStart {
    border: 1px solid #e0e0e0;
    background: #ffffff;
    min-height: 40px;
    width: 44px !important;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 20px;
    }
  }
  input[type="text"] {
    border: 1px solid #e0e0e0;
    background: #ffffff;
    min-height: 20px;
    border-radius: 4px;
    margin: 0 10px 0 0;
    width: 200px;
  }
}

.activityBtn {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
  button {
    background: #003471;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
    border-radius: 64px 0px 0px 64px !important;
    color: #ffffff;
    height: 36px !important;
    min-width: 54px !important;
    margin-right: -24px;
    margin-top: 10px;
    span {
      div {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        svg {
          margin-right: 5px;
          margin-left: 10px;
        }
      }
      svg {
        font-size: 14px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &:hover {
      background: #000000;
    }
  }
}

.subscribe-newsletter {
  label {
    display: flex;
    align-items: center;
    input[type="checkbox"] {
      margin-right: 10px;
      margin-top: 2px;
    }
  }
}

// .rbc-event {
//   background: none !important;
//   border: 0 !important;
//   .rbc-off-range-bg {
//     // background: #FFFFFF !important;
//     &.month-layout {
//       background: none;
//       font-size: 12px;
//       span {
//         &.time {
//           color: #000000 !important;
//         }
//       }
//     }
//   }
// }

a.rbc-show-more:link,
a.rbc-show-more:visited,
a.rbc-show-more:active {
  color: #000000 !important;
  text-decoration: none !important;
}

.rbc-calendar {
  align-items: end !important;
  .rbc-month-view {
    // width: 92%;
    // width: 93.1%;
    border: 0;
    background: #ffffff;
    .rbc-month-header {
      .rbc-header {
        border-bottom: 0;
        margin: 17px 0 0 0;
        div {
          span {
            color: #9e9e9e !important;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
    .rbc-month-row {
      margin: 0;
      .rbc-date-cell {
        text-align: center;
        padding: 0;
        margin: 7px 0 0 0;
      }
    }
    .rbc-event {
      background: none !important;
      .month-layout {
        background: none !important;
        font-size: 12px;
        &:focus-visible {
          outline: none;
        }
        span:nth-child(2) {
          font-weight: 600;
        }
        span {
          &.time {
            background: none !important;
            color: #000000 !important;
          }
        }
      }
    }
    .rbc-day-bg {
      background: none !important;
    }
    // @media screen and (max-width: 1536px) {
    //   width: 91.3%;
    // }
    // @media screen and (max-width: 1366px) {
    //   width: 90.2%;
    // }
  }
}

.rbc-agenda-view {
  // width: 93.1%;
  width: 100%;
  background: #ffffff;
  font-size: 14px;
  table {
    &.rbc-agenda-table {
      border: 0 !important;
      thead {
        display: none;
      }
      tbody {
        > tr {
          > td {
            + td {
              border-left: 0 !important;
            }
            &.rbc-agenda-time-cell {
              display: none;
            }
            &.rbc-agenda-date-cell {
              div {
                margin: 0;
                width: 130px;
                display: flex;
                align-items: center;
                span {
                  border-radius: 100% !important;
                  width: 38px;
                  height: 38px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: bold;
                  font-size: 16px;
                  margin: 0 15px 0 0;
                }
              }
            }
          }
        }
      }
      .rbc-off-range-bg {
        background: none;
        span {
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
  .rbc-agenda-content {
    padding: 20px 15px;
    @media screen and (max-width: 1536px) {
      padding: 10px 0px;
    }
  }
  @media screen and (max-width: 1536px) {
    // width: 91.3%;
    font-size: 12px;
  }
  // @media screen and (max-width: 1366px) {
  //   width: 90.2%;
  // }
  .rbc-agenda-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
  }
}

.react-datepicker-popper {
  z-index: 5 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

@media screen and (max-width: 1536px) {
  .MuiAutocomplete-listbox {
    font-size: 12px;
  }
}

@media screen and (max-width: 1366px) {
  .MuiAutocomplete-listbox {
    font-size: 11px;
  }

  #menu-repeatSelect {
    .MuiMenu-list {
      li {
        font-size: 14px;
      }
    }
  }
}

/* M E S S A G E S */

.chatWindow {
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  border-radius: 6px;
  height: 79vh;
  width: 100%;
  min-width: 470px;
}

.chatWindowList {
  display: flex;
  background: rgb(255, 255, 255);
  border-radius: 6px;
  flex-direction: column;
  margin-right: 1px;
  height: 100%;
  overflow-y: auto;
}


.chatListWrapper{
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
}

.chat {
  background: none;
  margin-top: 16px;
  scroll-behavior: smooth;
  margin-right: 5px;
  margin-left: 5px;
}

.chat li {
  padding: 0.2rem;
  overflow: hidden;
  display: flex;
}
.chat .avatar {
  position: relative;
  display: block;
  z-index: 2;
}
.chat .avatar img {
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.chat .uid img {
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.chat .day {
  position: relative;
  display: block;
  text-align: center;
  color: #c0c0c0;
  height: 20px;
  text-shadow: 7px 0px 0px #e5e5e5, 6px 0px 0px #e5e5e5, 5px 0px 0px #e5e5e5,
    4px 0px 0px #e5e5e5, 3px 0px 0px #e5e5e5, 2px 0px 0px #e5e5e5,
    1px 0px 0px #e5e5e5, 1px 0px 0px #e5e5e5, 0px 0px 0px #e5e5e5,
    -1px 0px 0px #e5e5e5, -2px 0px 0px #e5e5e5, -3px 0px 0px #e5e5e5,
    -4px 0px 0px #e5e5e5, -5px 0px 0px #e5e5e5, -6px 0px 0px #e5e5e5,
    -7px 0px 0px #e5e5e5;
  box-shadow: inset 20px 0px 0px #e5e5e5, inset -20px 0px 0px #e5e5e5,
    inset 0px -2px 0px #d7d7d7;
  line-height: 38px;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.other .msg {
  order: 1;
}
.other:before {
  content: "";
  position: relative;
  top: 0px;
  right: 0px;
  left: 40px;
  width: 0px;
  height: 0px;
  border: 5px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.self {
  justify-content: flex-end;
  align-items: flex-end;
  border: 1px;
  padding: "10px";
  order: 1;
}
.self .msg {
  order: 1;
}
.self .avatar {
  order: 2;
}
.self .avatar:after {
  content: "";
  position: relative;
  display: inline-block;
  bottom: 19px;
  right: 0px;
  width: 0px;
  height: 0px;
  border: 5px solid #fff;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 0px 2px 0px #d4d4d4;
}
.msg {
  background: #003471;
  // min-width: fit-content;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
  font-size: 16px;
  font-weight: 400;
  font-family: "OpenSans";
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 400px;
  overflow-wrap: break-word;
}

.msg-other {
  background: #f2f2f2;
  // min-width: fit-content;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 400px;
}

.msg p {
  font-size: 0.8rem;
  margin: 0 0 0.2rem 0;
  color: rgb(81, 84, 255);
}
.chatInputWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 80px;
  width: 100%;
  background-color: #f2f2f2;
  padding: 12px;
}

input.textarea {
  height: 48px;
  flex-grow: 1;
  background: #fafafa;
  color: black;
  font-weight: 400;
  font-size: 16px;
  font-family: "OpenSans";
  border-radius: 4px;
}

.message-self {
  font-size: 15px !important;
  margin-right: 10px;
  align-self: center;
}

.message-other {
  font-size: 14px !important;
  margin-left: 10px;
  align-self: center;
}
.message-other-heading {
  font-size: 14px;
  font-style: normal;
  margin-left: 10px;
  align-self: center;
  font-weight: 600;
  font-family: "OpenSans";
  color: #333333;
}

.message-other-chattime {
  font-size: 14px;
  margin-left: 10px;
  align-self: stretch;
  width: 148px;
  font-weight: 400;
  font-family: "OpenSans";
  color: #333333;
}

.MuiAutocomplete-root { 
  #selectedRecipients {
    border: none !important;
  }
}

#chat-settings {
  .MuiMenu-list {
    height: auto !important;
  }
}
