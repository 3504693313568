@font-face {
  font-family: "fonticon";
  src: url("fonticon.eot?qfkvco");
  src: url("fonticon.eot?qfkvco#iefix") format("embedded-opentype"),
    url("fonticon.ttf?qfkvco") format("truetype"),
    url("fonticon.woff?qfkvco") format("woff"),
    url("fonticon.svg?qfkvco#fonticon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ticon-"],
[class*=" ticon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "fonticon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.ticon-info:before {
  content: "\e921";
}
.ticon-settings:before {
  content: "\e920";
}
.ticon-user:before {
  content: "\e91e";
}
.ticon-logout:before {
  content: "\e91f";
}
.ticon-chatting:before {
  content: "\e91d";
}
.ticon-lotus:before {
  content: "\e91c";
}
.ticon-edit-pen:before {
  content: "\e912";
}
.ticon-location:before {
  content: "\e913";
}
.ticon-heart:before {
  content: "\e914";
}
.ticon-home:before {
  content: "\e915";
}
.ticon-notification:before {
  content: "\e916";
  color: #103170;
}
.ticon-chart:before {
  content: "\e917";
}
.ticon-reward:before {
  content: "\e918";
}
.ticon-schedule:before {
  content: "\e919";
}
.ticon-trash-line:before {
  content: "\e91a";
}
.ticon-edit-pencil:before {
  content: "\e91b";
}
.ticon-sync-off:before {
  content: "\e910";
}
.ticon-sync-on:before {
  content: "\e911";
}
.ticon-check:before {
  content: "\e90d";
}
.ticon-teacher:before {
  content: "\e90e";
}
.ticon-therapist:before {
  content: "\e90f";
}
.ticon-camera:before {
  content: "\e90c";
}
.ticon-trash:before {
  content: "\e90a";
}
.ticon-plus:before {
  content: "\e90b";
}
.ticon-arrow-right:before {
  content: "\e900";
}
.ticon-badge:before {
  content: "\e901";
}
.ticon-bell:before {
  content: "\e902";
}
.ticon-calender-clock:before {
  content: "\e903";
}
.ticon-chevron-down:before {
  content: "\e904";
}
.ticon-emoti:before {
  content: "\e905";
}
.ticon-fence:before {
  content: "\e906";
}
.ticon-graph:before {
  content: "\e907";
}
.ticon-meter:before {
  content: "\e908";
}
.ticon-profile-card:before {
  content: "\e909";
}
